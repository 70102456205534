import React, { useEffect } from "react";
import { Accordion, Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
function SideBar(props) {
  useEffect(() => {
    var div = document.querySelector(
      ".accordion-item .accordion-body .nav-link.active"
    );
    if (div) {
      div.closest(".accordion-item").classList.add("active");
     
    }
  });
  /******************* 
@Purpose : Used for render SideBar
@Parameter : {}
@Author : INIC
******************/
  return (
    <>
      <div
        className={`d-flex flex-column flex-shrink-0 sidebar-block ${
          props.sidebar ? "sidebar-show" : ""
        }`}
      >
        <Nav className=" nav-pills flex-column mb-auto">
          <Accordion defaultActiveKey="0">
            <Nav.Item>
              <NavLink to="/dashboard" className="nav-link">
                <span className="icon-home"></span>
                <span className="sidebar-text">Dashboard</span>
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/dashboard">
                <span className="icon-chart-line"></span>
                <span className="sidebar-text">Reports & Goals</span>
              </Nav.Link>
            </Nav.Item>
          
            <Nav.Item>
              <NavLink className="nav-link" to="/home">
                <span className="icon-calendar-alt"></span>
                <span className="sidebar-text">Appointments</span>
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink className="nav-link" to="/home">
                <span className="icon-share-alt"></span>
                <span className="sidebar-text">Refer a Friend</span>
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink className="nav-link" to="/home">
                <span className="icon-scan"></span>
                <span className="sidebar-text">Delivery QR code</span>
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink className="nav-link" to="/home">
                <span className="icon-cog"></span>
                <span className="sidebar-text">Account Settings</span>
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink className="nav-link" to="/home">
                <span className="icon-mug"></span>
                <span className="sidebar-text">Support </span>
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink className="nav-link" to="/home">
                <span className="icon-store"></span>
                <span className="sidebar-text">Store </span>
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink className="nav-link" to="/home">
                <span className="icon-bell"></span>
                <span className="sidebar-text">Notification </span>
              </NavLink>
            </Nav.Item>
            <Nav.Item>
              <NavLink className="nav-link" to="/home">
                <span className="icon-plus-line"></span>
                <span className="sidebar-text">Register a Kit</span>
              </NavLink>
            </Nav.Item>
           
            <Nav.Item>
              <NavLink className="nav-link" to="/assigned-tasks">
                <span className="icon-truck-alt"></span>
                <span className="sidebar-text">Delivery</span>
              </NavLink>
            </Nav.Item>

            <Nav.Item>
              <Accordion.Item eventKey="1">
                <NavLink className="nav-link" to="/client-profile">
                  <span className="icon-user-profile"></span>
                  <span className="sidebar-text"> profile</span>
                </NavLink>
                <Accordion.Header>
                  <span className="icon-angle-down"></span>
                </Accordion.Header>
                <Accordion.Body>
                  <Nav.Item>
                    <NavLink className="nav-link" to="/password">
                      <span className="icon-lock-log-alt"></span>
                      <span className="sidebar-text">Password</span>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink className="nav-link" to="/wallates">
                      <span className="icon-wallet-alt"></span>
                      <span className="sidebar-text">Wallets</span>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink className="nav-link" to="/home">
                      <span className="icon-bell-empty"></span>
                      <span className="sidebar-text">Notification</span>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink className="nav-link" to="/home">
                      <span className="icon-file-blank"></span>
                      <span className="sidebar-text">Privacy</span>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink className="nav-link" to="/home">
                      <span className="icon-order-notes"></span>
                      <span className="sidebar-text">Orders and Kits </span>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink className="nav-link" to="/home">
                      <span className="icon-file-blank"></span>
                      <span className="sidebar-text">Orders and Kits </span>
                    </NavLink>
                  </Nav.Item>
                  <Nav.Item>
                    <NavLink className="nav-link" to="/family-member">
                      <div>
                        <span className="icon-file-question-alt"></span>
                        <span className="sidebar-text">Questionnaire</span>
                      </div>
                    </NavLink>
                  </Nav.Item>
                </Accordion.Body>
              </Accordion.Item>
            </Nav.Item>
          </Accordion>
        </Nav>
      </div>
    </>
  );
}

export default SideBar;
