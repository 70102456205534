import React, { Fragment, useState } from "react";
import { FormGroup, Form, Row, Col } from "react-bootstrap";
import { useFormik } from "formik";
import BtnMain from "../../components/buttons/btn_main";
import TextField from "../../components/inputs/textfield";
import UploadFile from "../../components/inputs/UploadFile";
import Select from "react-select";
import Lukus from "./../../assets/images/temp-img/lukus.png";
import { sendProfileData } from "../../store/Actions";
import DateTime from "react-datetime";
import { profileSchema } from "../../utils/schema";
import moment from "moment";
import Layout from "../../components/Layout/dashboard-layout/Layout";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../components/Layout/loader/Loader";
import { API_DOMAIN } from "../../config/index";
import ImgText from "../../components/img-text/ImgText";
function ClientProfile(_props) {
  const [previewBanner, SetpreviewBanner] = useState("");
  const isLoading = useSelector((state) => state?.ui?.loaders?.profile);
  const dispatch = useDispatch();
  const [successMsg, setSuccess] = useState(false);
  const userInfo = useSelector((state) => state?.user?.userInfo);
  const [error, setError] = useState("");
 
  var state = "";
  if (userInfo.stateName != null) {
    state = userInfo.stateName;
  }
  var image = API_DOMAIN + "" + userInfo.avatarUrl;
  const formik = useFormik({
    initialValues: {
      Title: "",
      FirstName: userInfo.firstName,
      LastName: userInfo.lastName,
      Email: userInfo.email,
      State: state,
      birthdate: "",
      image: "",
    },
    onSubmit(values) {
      const formData = new FormData();
      formData.append("Title", values.Title);
      formData.append("FirstName", values.FirstName);
      formData.append("LastName", values.LastName);
      formData.append("Email", values.Email);
      formData.append("State", values.State);
      formData.append("birthdate", values.birthdate);
      formData.append("image", values.image);
      dispatch(sendProfileData(formData, "profile")).then((res) => {
        if (res.success) {
          setSuccess(true);
          setError("");
        } else {
          setError(res.errorMessage);
          setSuccess(false);
        }
      });
    },
    validationSchema: profileSchema,
  });
  const titleOptions = [
    { value: "", label: "Select Title" },
    { value: "Prof", label: "Prof" },
    { value: "Dr", label: "Dr." },
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Ms", label: "Ms" },
    { value: "Miss", label: "Miss" },
  ];

  const onDrop = (files) => {
    if (files) {
      SetpreviewBanner(global.URL.createObjectURL(files[0]));
      formik?.setFieldValue("image", files[0]);
    }
  };
  /******************* 
@purpose : used for form Handle
@Parameter : {e} 
@Author : INIC
******************/
  const handleSubmit = (e) => {
    e.preventDefault();

    formik.submitForm();
  };
  /******************* 
@purpose : Render Profile Page UI 
@Author  : INIC
******************/

  return (
    <Layout>
      {isLoading && <Loader text="Loading" />}
      <div className="dashboard-inner-content">
        <div className="profile-main-wrap">
          {error && (
            <div className="login-block ">
            
              <ImgText
                userBlock={"user-block-sm pe-0 align-items-start"}
                userIc={"danger-bg"}
                userIcon={"icon-times-circle white-text"}
              >
                <div className="sm-text light-black-text user-textHeading">
                  {error}
                </div>
              </ImgText>
            </div>
          )}
          {successMsg && (
            <div className="login-block mb-3 pb-3">
             
              <ImgText
                userBlock={"user-block-sm pe-0 align-items-start"}
                userIc={"success-bg"}
                userIcon={"icon-check-circle white-text"}
              >
                <div className="sm-text light-black-text user-textHeading">
                  You have successfully changed your password on Rightangle.
                </div>
              </ImgText>
            </div>
          )}
          <Form onSubmit={handleSubmit} className="dashboard-form">
            <div className="big-heading h3">Profile</div>
            <div className="profile-wrap client-profile-wrap">
              <div className="label-heading xs-text b-700 mb-3">AVATAR</div>
              <div className="profile-block">
                <UploadFile onDrop={onDrop} accept="image/*">
                  {previewBanner ? (
                    <div className="preview-wrap edit-banner-img">
                      <div className="preview-img">
                        <div className="preview-action">
                          <span
                            className="preview-ic icon-edit mb-0 me-1"
                            onClick={(e) => {
                              SetpreviewBanner("");
                              e.preventDefault();
                            }}
                          ></span>
                          <div className="b-700 sm-text">Edit</div>
                        </div>
                        <div
                          className="background-img"
                          style={{
                            backgroundImage: "url(" + previewBanner + ")",
                          }}
                        ></div>
                      </div>
                    </div>
                  ) : (
                    <Fragment>
                      <div className="profile-upload">
                        <span className="icon-image-upload"></span>
                        <div className="profileUpload-heading sm-text b-700">
                          Upload Avatar
                        </div>
                        <div className="profileUpload-subheading xs-text">
                          Photo must be below 5MB.
                        </div>
                      </div>
                    </Fragment>
                  )}
                  {formik.errors.image ? (
                    <div className="message-block">{formik.errors.image}</div>
                  ) : null}
                </UploadFile>
                <div className="tag-block success-tag-block ms-4">
                  <div className="tag-tile ">
                    <span className="icon-check m-0 xl-text"></span>ID Verified
                  </div>
                </div>
              </div>
            </div>

            <Row>
              <Col xs={12}>
                <div className="label-heading xs-text b-700 mb-3">
                  PERSONAL INFORMATION
                </div>
              </Col>
              <Col xs={12} md={6}>
                <div className="input-withSelect">
                  <FormGroup className="form-group">
                    <Select
                      classNamePrefix="custom-select"
                      placeholder="Select Title"
                      type="text"
                      id="title"
                      name="Title"
                      onChange={(e) => {
                        formik?.setFieldValue("Title", e.value);
                      }}
                      options={titleOptions}
                    />
                    {formik.errors.Title ? (
                      <div className="message-block">{formik.errors.Title}</div>
                    ) : null}
                  </FormGroup>
                  <FormGroup className="form-group">
                    <TextField
                      type="text"
                      placeholder="First name"
                      name="FirstName"
                      formik={formik}
                      id="firstName"
                      showUntoucherError
                    />
                  </FormGroup>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup className="form-group">
                  <TextField
                    type="text"
                    placeholder="Last name"
                    name="LastName"
                    formik={formik}
                    id="lastName"
                    showUntoucherError
                  />
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup className="form-group">
                  <TextField
                    type="text"
                    placeholder="State Name"
                    name="State"
                    formik={formik}
                    id="State"
                    showUntoucherError
                  />
                </FormGroup>
              </Col>

              <Col xs={12} md={4}>
                <FormGroup
                  className={`form-group input-ic-wrap  ${
                    formik.values.birthdate ? "date-filled" : "date-empty"
                  }`}
                >
                  <DateTime
                    name="birthdate"
                    placeholder="Date of birth"
                    value={formik.values.birthdate}
                    locale="en-US"
                    dateFormat="DD/MM/YYYY"
                    closeOnSelect
                    timeFormat={false}
                    onChange={(e) => {
                      var dboData = moment(e).format("YYYY-MM-DD");
                      formik?.setFieldValue("birthdate", dboData);
                    }}
                    inputProps={{
                      placeholder: "Date of birth",
                    }}
                  />
                  <i className="icon-calender" aria-hidden="true"></i>
                  {formik.errors.birthdate ? (
                    <div className="message-block">
                      {formik.errors.birthdate}
                    </div>
                  ) : null}
                </FormGroup>
              </Col>
              <Col xs={12} md={4}>
                <FormGroup className="form-group input-ic-wrap">
                  <TextField
                    type="text"
                    placeholder="Email"
                    name="Email"
                    formik={formik}
                    id="lastName"
                    showUntoucherError
                  />
                  <i className="icon-info-circle" aria-hidden="true"></i>
                </FormGroup>
              </Col>
              <Col xs={12} className="personal-info-save-btn">
                <BtnMain
                  text="Save Changes"
                  className="btn btn-primary saveChanges-btn lg-btn"
                  type="submit"
                >
                  {" "}
                </BtnMain>
              </Col>
            </Row>
          </Form>
          <div className="family-member-wrap">
            <Row className="profileInfo-block">
              <Col xs={12} lg={12} className="">
                <div className="label-heading xs-text b-700 mb-3">
                  FAMILY MEMBERS
                </div>
              </Col>
              <Col xs={12} lg={6} className="">
                <div className="profileInfo-tile p-3 mb-4">
                  <ImgText userImg={"rounded-border"} userImgUrl={Lukus}>
                    <div className="sm-text black-text b-700 user-textHeading">
                      Lukus Jackson
                    </div>
                    <div className="sm-text black-text user-textSubHeading">
                      38 years old
                    </div>
                  </ImgText>
                  <div className="profileInfo-action d-flex">
                    <Link to={"/"} className="btn gray-line-btn">
                      View
                    </Link>
                  </div>
                </div>
              </Col>
              <Col xs={12} lg={6} className="">
                <div className="profileInfo-tile p-3 mb-4">
                  <ImgText userImg={"rounded-border"} userImgUrl={Lukus}>
                    <div className="sm-text black-text b-700 user-textHeading">
                      Samantha Jackson
                    </div>
                    <div className="sm-text black-text user-textSubHeading">
                      38 years old
                    </div>
                  </ImgText>
                  <div className="profileInfo-action d-flex align-items-center">
                    <div className="tag-block blue-tag-block me-3">
                      <div className="tag-tile m-0">Primary</div>
                    </div>
                    <Link to={"/"} className="btn gray-line-btn">
                      View
                    </Link>
                  </div>
                </div>
              </Col>
              <Col xs={12} lg={6} className="">
                <div className="add-more">
                  <div className="add-more-img me-3">
                    <span className="icon-plus"></span>
                  </div>
                  <div className="add-more-text sm-text primary-text">
                    Connect with a Family Member account
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default ClientProfile;
