
import { combineReducers } from 'redux';
//Import all reducer
import ui from './Ui';
import user from './User';
/******************* 
@purpose : Combine Reducer
@Parameter : {ui, user}
@Author : INIC
******************/
const appReducer = combineReducers({
    ui,
    user
});

export default appReducer;
