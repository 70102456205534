import React from "react";
import { Row, Col, FormGroup } from "react-bootstrap";
import BtnMain from "../../components/buttons/btn_main";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout/dashboard-layout/Layout";
import DataTable, { Media } from "react-data-table-component";
import TextField from "../../components/inputs/textfield";
import { useFormik } from "formik";
import moment from "moment";
import DateTime from "react-datetime";
function Wallates(_props) {
  const formik = useFormik({
    initialValues: {
      selectProfession: "",
      registration: "",
    },
    onSubmit(_values) {
     
    },
  });
  const columns = [
    {
      name: "STATUS ",
      selector: (row) => row.status,
    },
    {
      name: "DATE",
      selector: (row) => row.date,
      hide: Media.SM,
    },
    {
      name: "NAME",
      selector: (row) => row.name,
    },
    {
      name: "AMOUNT",
      selector: (row) => row.amount,
    },
    {
      name: " ",
      selector: (row) => row.action,
    },
  ];
  const data = [
    {
      status: (
        <div className="tag-block green-tag-block ">
          <div className="tag-tile m-0">Paid</div>{" "}
        </div>
      ),
      date: "Thur, Feb 22, 2021",
      name: "Ricky Utah",
      amount: "￡50.00",
      action: (
        <div className="d-flex justify-content-end w-100">
          <Link to={"/"} className="btn gray-line-btn">
            View in Stripe
          </Link>
        </div>
      ),
    },
    {
      status: (
        <div className="tag-block green-tag-block ">
          <div className="tag-tile m-0">Paid</div>{" "}
        </div>
      ),
      date: "Thur, Feb 22, 2021",
      name: "Ricky Utah",
      amount: "￡50.00",
      action: (
        <div className="d-flex justify-content-end w-100">
          <Link to={"/"} className="btn gray-line-btn">
            View in Stripe
          </Link>
        </div>
      ),
    },
    {
      status: (
        <div className="tag-block red-tag-block  ">
          <div className="tag-tile m-0">Failed</div>{" "}
        </div>
      ),
      date: "Thur, Feb 22, 2021",
      name: "Ricky Utah",
      amount: "￡50.00",
      action: (
        <div className="d-flex justify-content-end w-100">
          <Link to={"/"} className="btn gray-line-btn">
            View in Stripe
          </Link>
        </div>
      ),
    },
  ];
  /******************* 
@Purpose : Used for render Wallates UI
@Parameter : {}
@Author : INIC
******************/

  return (
    <Layout>
      <div className="dashboard-inner-content dashboard-wrap pb-5">
        <div className="big-heading h3">Wallets</div>
        <div className="paymentStatus-wrap">
          <div className="label-heading xs-text b-700 mb-3">OVERVIEW</div>
          <div className="paymentStatus-block ">
            <Row>
              <Col xs={12} md={4}>
                <div className="paymentStatus-tile">
                  <div className="paymentStatus-innerTile">
                    <div className="paymentStatus-heading sm-text">
                      Outstanding
                    </div>
                    <div className="paymentStatus-amount h3 mb-0">
                      <span className="lg-text">￡</span>50.00
                    </div>
                  </div>
                  <div className="paymentStatus-footer sm-text">
                    <Link to={"/"} className="black-text">
                      View in Payments
                    </Link>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="paymentStatus-tile">
                  <div className="paymentStatus-innerTile">
                    <div className="paymentStatus-heading sm-text">Pending</div>
                    <div className="paymentStatus-amount h3 mb-0">
                      <span className="lg-text">￡</span>75.00
                    </div>
                  </div>
                  <div className="paymentStatus-footer sm-text">
                    <Link to={"/"} className="black-text">
                      View in Payments
                    </Link>
                  </div>
                </div>
              </Col>
              <Col xs={12} md={4}>
                <div className="paymentStatus-tile">
                  <div className="paymentStatus-innerTile">
                    <div className="paymentStatus-heading sm-text">Paid</div>
                    <div className="paymentStatus-amount h3 mb-0">
                      <span className="lg-text">￡</span>2,300.00
                    </div>
                  </div>
                  <div className="paymentStatus-footer sm-text">
                    <Link to={"/"} className="black-text">
                      View in Payments
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>

        <div className="label-heading xs-text b-700 mb-3">PAYMENT HISTORY</div>
        <Row className="align-items-center">
          <Col xs={12} md={4} className="mb-4">
            <FormGroup className="form-group  input-ic-wrap mb-0">
              <TextField
                type="text"
                placeholder="Search"
                name="Search"
                formik={formik}
                className="search-input-wrap"
                aria-label="Search"
                showUntoucherError
              />
              <i className="icon-search" aria-hidden="true"></i>
            </FormGroup>
          </Col>
          <Col xs={12} md={4} className="mb-4">
            <FormGroup
              className={`form-group input-ic-wrap mb-0  ${
                formik.values.birthdate ? "date-filled" : "date-empty"
              }`}
            >
              <DateTime
                name="birthdate"
                placeholder="Date of birth"
                value={formik.values.birthdate}
                locale="en-US"
                dateFormat="DD/MM/YYYY"
                closeOnSelect
                timeFormat={false}
                onChange={(e) => {
                  var dboData = moment(e).format("YYYY-MM-DD");
                  formik?.setFieldValue("birthdate", dboData);
                }}
                inputProps={{
                  placeholder: "Date of birth",
                }}
              />
              <i className="icon-calendar-alt" aria-hidden="true"></i>
              {formik.errors.birthdate ? (
                <div className="message-block">{formik.errors.birthdate}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col
            xs={12}
            md={4}
            className="mb-4 d-flex justify-content-center justify-content-md-end"
          >
            <BtnMain
              text="Save Changes"
              className="btn btn-purple "
              type="button"
            >
              {" "}
            </BtnMain>
          </Col>
        </Row>
        <DataTable
          columns={columns}
          data={data}
          // expandableRows="true"
        />
      </div>
    </Layout>
  );
}
export default Wallates;
