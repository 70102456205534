import React from "react";
import { Breadcrumb } from "react-bootstrap";
import ProfilePhoto from "./../../assets/images/temp-img/profile_photo.png";
import Layout from "../../components/Layout/dashboard-layout/Layout";
import { Link } from "react-router-dom";
import questionMark from "./../../assets/images/color-icon/question-mark.svg";
function FamilyMember(_props) {
  /******************* 
@purpose : Render FamilyMember Page UI 
@Author  : INIC
******************/

  return (
    <Layout>
      <div className="dashboard-inner-content">
        <Breadcrumb>
          <Breadcrumb.Item href="/assigned-tasks" className="sm-text ">
            Family members <span className="icon-arrow-right"></span>{" "}
          </Breadcrumb.Item>
          <Breadcrumb.Item active className="sm-text">
            Sarah Jack
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="current-report-wrap">
          <div className="currentReportProfile-block">
            <div className="user-block user-block-lg p-0">
              <div className="userImg-block">
                <div
                  className="user-img"
                  style={{ backgroundImage: "url(" + ProfilePhoto + ")" }}
                ></div>
              </div>
              <div className="user-text">
                <div className="h3 mb-4 black-text b-700">Sarah Jack</div>
                <div className="tag-block blue-tag-block">
                  <div className="tag-tile">Female</div>
                  <div className="tag-tile">24 Years Old</div>
                  <div className="tag-tile">69 kg</div>
                  <div className="tag-tile">White</div>
                  <div className="tag-tile">United Kingdom</div>
                  <div className="tag-tile">Drinker</div>
                  <div className="tag-tile">Smoker</div>
                  <div className="tag-tile">Self-employed</div>
                  <div className="tag-tile">Alergies</div>
                  <div className="tag-tile">Asthma</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="idea-box align-items-center  mb-4">
          <div className="idea-ic">
            <i className="icon-info-circle"></i>
          </div>
          <div className="idea-text">
            <div className="idea-subheading text-size-455">
              All family members over the age of 18 need to manage their own
              profile from their own account.
            </div>
          </div>
        </div>
        <div className="profileInfo-wrap">
          <div className="grey-text xs-text mb-3 b-700 profileInfo-heading">
            Pharmacy Medicine Consultation{" "}
          </div>
          <div className="profileInfo-block black-text">
            <div className="profileInfo-tile">
              <div className="user-block">
                <div className="userImg-block ">
                  <div className="userIc light-bg">
                    <img src={questionMark} alt={questionMark} />
                  </div>
                </div>
                <div className="user-text">
                  <div className="sm-text light-black-text b-700 user-textHeading">
                    Completed
                  </div>
                  <div className="sm-text grey-text user-textSubHeading">
                    June 23, 2021 at 21:34
                  </div>
                </div>
              </div>
              <div className="profileInfo-action d-flex">
                <Link to={"/"} className="btn gray-line-btn ">
                  View{" "}
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default FamilyMember;
