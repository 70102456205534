import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { register } from "../../store/Actions";
import { useFormik } from "formik";
import { signupSchema } from "../../utils/schema";
import Layout from "../Layout/Layout";
import { Container, Row, Col, Form, FormGroup } from "react-bootstrap";
import { Link } from "react-router-dom";
import TextField from "../inputs/textfield";
import BtnMain from "../buttons/btn_main";
import Select from "react-select";
import Loader from "../Layout/loader/Loader";
import GoogleLogin from "react-google-login";
import AppleLogin from "react-apple-login";
import DateTime from "react-datetime";
import moment from "moment";
import { APPLE_ID, APPLE_REDIRECT_URL, GOOGLE_ID } from "../../config/index";
import ImgText from "../img-text/ImgText";

function Step1(props) {
  const isLoading = useSelector((state) => state?.ui?.loaders?.register);
  const [error, setError] = useState("");
  const [successMsg, setSuccess] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const { useAppleLoginPopup = true } = props;
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };
  const titleOptions = [
    { value: "", label: "Select Title" },
    { value: "Prof", label: "Prof" },
    { value: "Dr", label: "Dr." },
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Ms", label: "Ms" },
    { value: "Miss", label: "Miss" },
  ];
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      title: "",
      firstName: "",
      lastName: "",
      birthdate: "",
      email: "",
      password: "",
      confirmPassword: "",
      ExternalAuth: false,
      serviceRulesAgreed: false,
      policyRulesAgreed: false,
    },
    onSubmit(values, { resetForm }) {
      const body = values;
      dispatch(register(body, "register")).then((res) => {
        if (res.success) {
          setSuccess(true);
          setError("");
          resetForm({ values: "" });
        } else {
          setError(res.errorMessage);
          setSuccess(false);
        }
      });
    },
    validationSchema: signupSchema,
  });

  /******************* 
    @Purpose : Used for Handle Form Submit 
    @Parameter : {e}
    @Author : INIC
  *******************/
  const handleSubmit = (e) => {
    e.preventDefault();
    formik.submitForm();
  };
  /******************* 
    @Purpose : Used for Handle Social Login 
    @Parameter : {user}
    @Author : INIC
  *******************/
  const handleSocialLogin = (type, user) => {
    if (type === "google") {
      const profile = user.profileObj;
      formik.setFieldValue(
        "FullName",
        profile["givenName"] + " " + profile["familyName"]
      );
      formik.setFieldValue("email", profile["email"]);
      formik.setFieldValue("ExternalAuth", true);
      formik.setFieldValue("Barcode", user.accessToken);
    }
  };
  /******************* 
@purpose : Render Step 1 UI For Onboarding 
@Author  : INIC
******************/
  return (
    <Layout>
      {isLoading && <Loader text="Loading" />}
      <Container className="auth-main">
        <div className="auth-box">
          <div className="auth-heading">Create a User Account</div>
          <Row className="authGoogleApple-wrap gx-3">
            <Col sm={6}>
              <GoogleLogin
                className="googlesignin-box"
                icon={false}
                clientId={GOOGLE_ID}
                buttonText="Sign up with Google"
                onSuccess={(user) => handleSocialLogin("google", user)}
                onFailure={(error) => handleSocialLogin("google", error)}
              />
            </Col>
            <Col sm={6} className="mt-sm-0 mt-2">
              <div className="signWith-apple">
                <button className="googlesignin-box">Sign up with Apple</button>
                <AppleLogin
                  clientId={String(APPLE_ID)}
                  redirectURI={APPLE_REDIRECT_URL}
                  callback={(user) => handleSocialLogin("apple", user)}
                  scope={"email name"}
                  responseMode={"form_post"}
                  designProp={{
                    height: 30,
                    width: 140,
                    color: "white",
                    border: false,
                    type: "sign-in",
                    border_radius: 15,
                    scale: 1,
                    locale: "en_US",
                  }}
                  usePopup={useAppleLoginPopup ? true : false}
                  onFailure={(error) => handleSocialLogin("apple", error)}
                ></AppleLogin>
              </div>
            </Col>
          </Row>
          <div className="or-text">or</div>

          <div className="form-wrap">
            <Form onSubmit={handleSubmit} noValidate>
              <FormGroup className="form-group">
                <Select
                  classNamePrefix="custom-select"
                  placeholder="Select Title"
                  type="text"
                  id="title"
                  name="title"
                  onChange={(e) => {
                    formik?.setFieldValue("title", e.value);
                  }}
                  options={titleOptions}
                />
                {formik.errors.title ? (
                  <div className="message-block">{formik.errors.title}</div>
                ) : null}
              </FormGroup>
              <FormGroup className="form-group">
                <TextField
                  type="text"
                  placeholder="First name"
                  name="firstName"
                  formik={formik}
                  id="firstName"
                  showUntoucherError
                />
              </FormGroup>
              <FormGroup className="form-group">
                <TextField
                  type="text"
                  placeholder="Last name"
                  name="lastName"
                  formik={formik}
                  id="lastName"
                  showUntoucherError
                />
              </FormGroup>

              <FormGroup className="form-group input-ic-wrap">
                <TextField
                  type="email"
                  placeholder="Email address"
                  name="email"
                  formik={formik}
                  id="email"
                  showUntoucherError
                />
                <i className="icon-envelope-alt" aria-hidden="true"></i>
              </FormGroup>
              <FormGroup
                className={`form-group input-ic-wrap  ${
                  formik.values.birthdate ? "date-filled" : "date-empty"
                }`}
              >
                <DateTime
                  name="birthdate"
                  placeholder="Date of birth"
                  value={formik.values.birthdate}
                  locale="en-US"
                  dateFormat="DD/MM/YYYY"
                  closeOnSelect
                  timeFormat={false}
                  onChange={(e) => {
                    var dboData = moment(e).format("YYYY-MM-DD");
                    formik?.setFieldValue("birthdate", dboData);
                  }}
                  inputProps={{
                    placeholder: "Date of birth",
                  }}
                />
                <i className="icon-calender" aria-hidden="true"></i>
                {formik.errors.birthdate ? (
                  <div className="message-block">{formik.errors.birthdate}</div>
                ) : null}
              </FormGroup>
              <FormGroup className="form-group input-ic-wrap">
                <TextField
                  type={passwordShown ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  formik={formik}
                  id="password"
                  showUntoucherError
                />
                <i
                  className={`${
                    passwordShown ? "icon-eye-slash" : "icon-eye"
                  } toggle-password`}
                  aria-hidden="true"
                  onClick={togglePasswordVisiblity}
                ></i>
              </FormGroup>
              <FormGroup className="form-group input-ic-wrap">
                <TextField
                  type={confirmPasswordShown ? "text" : "password"}
                  placeholder="Confirm password"
                  name="confirmPassword"
                  formik={formik}
                  id="confirmPassword"
                  showUntoucherError
                />
                <i
                  className={`${
                    confirmPasswordShown ? "icon-eye-slash" : "icon-eye"
                  } toggle-password`}
                  aria-hidden="true"
                  onClick={toggleConfirmPasswordVisiblity}
                ></i>
              </FormGroup>
              <FormGroup className="form-group pt-3  ">
                <Form.Check
                  type={"checkbox"}
                  id={`term`}
                  name="serviceRulesAgreed"
                >
                  <Form.Check.Input
                    type={"checkbox"}
                    isValid
                    onChange={(e) => {
                      formik?.setFieldValue(
                        "serviceRulesAgreed",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label>
                    I agree to Rightanged’s{" "}
                    <Link to="/" className="b-700">
                      Terms of Service
                    </Link>
                  </Form.Check.Label>
                </Form.Check>
              </FormGroup>
              <FormGroup className="form-group pb-3 ">
                <Form.Check
                  type={"checkbox"}
                  id={`policy`}
                  name="policyRulesAgreed"
                >
                  <Form.Check.Input
                    type={"checkbox"}
                    isValid
                    onChange={(e) => {
                      formik?.setFieldValue(
                        "policyRulesAgreed",
                        e.target.checked
                      );
                    }}
                  />
                  <Form.Check.Label>
                    I agree to Rightanged’s{" "}
                    <Link to="/" className="b-700">
                      Privacy Policy
                    </Link>
                  </Form.Check.Label>
                </Form.Check>
              </FormGroup>

              <FormGroup className="form-group mb-0">
                <BtnMain
                  text="Create Account"
                  className={
                    !(formik.isValid && formik.dirty)
                      ? "btn btn-primary w-100 disabled-btn"
                      : "btn btn-primary w-100"
                  }
                  type="submit"
                  disabled={!(formik.isValid && formik.dirty)}
                />
              </FormGroup>
            </Form>
            {successMsg && (
              <div className="login-block mb-3 pb-3">
              
                <ImgText
                  userBlock={"user-block-sm pe-0 align-items-start"}
                  userIc={"success-bg"}
                  userIcon={"icon-check-circle white-text"}
                >
                  <div className="sm-text light-black-text user-textHeading">
                    You have successfully registered on Rightangle. Click to{" "}
                    <Link to={"/login"} className="b-700">
                      Login.
                    </Link>
                  </div>
                </ImgText>
              </div>
            )}
            {error && (
              <div className="login-block ">
               
                <ImgText
                  userBlock={"user-block-sm pe-0 align-items-start"}
                  userIc={"danger-bg"}
                  userIcon={"icon-times-circle white-text"}
                >
                  <div className="sm-text light-black-text user-textHeading">
                    {error}
                  </div>
                </ImgText>
              </div>
            )}
          </div>
        </div>
      </Container>
    </Layout>
  );
}
export default Step1;
