import React from "react";
import StepsHeader from "./steps-header/StepsHeader";
import BtnMain from "../../../../components/buttons/btn_main";

function Step3(props) {
  
  
  /******************* 
@Purpose : Used for render Step3 UI
@Parameter : {}
@Author : INIC
******************/
  return (
    <div className="step-inner-wrap">
      <StepsHeader stepsProps={props} />
      <div className="step-body">
        <div className="step-heading h6 mb-0">Disclaimer and Consent</div>
        <div className="step-list">
          <div className="mb-4 lg-text black-text">Your agreement with us</div>
          <ol type="1" className="number-list sm-text">
            <li>
              "We" are Rightangled Limited a company registered in England and
              Wales under company number 09866557 and with our registered office
              at Unit 1, 189-190 Shoreditch High street, London E1 6HU, GBR.
            </li>
            <li>
              “You" are the customer who has accessed our services through our
              websites or called our telephone number and has placed an order
              for our service(s).
            </li>
            <li>
              These terms and conditions along with our Privacy Policy
              (together, the "Terms") apply throughout our website and during
              any telephone call or written correspondence between you and us.
              If there is any conflict between these Terms and any terms or
              conditions found elsewhere on our website, or in any written or
              verbal communication between you and us, these Terms shall
              prevail.
            </li>
            <li>
              Test results and comments or interpretation provided by us in
              relation to those results are for the purposes of information
              only.
            </li>
            <li>
              This service is not a substitute for proper medical investigation
              and advice and we do not provide clinical or diagnostic services
              in relation to the tests. Our comments are based on information
              available to us at the time, which information may be insufficient
              to gain a complete understanding of your health or a particular
              condition you may be suffering from. If you have any concerns
              regarding your health or your test results you should discuss them
              directly with your GP or by calling 111.
            </li>
            <li>
              Based on the results of your test(s) and/or other information you
              may require further investigation or both.
            </li>
            <li>
              You agree that you are solely responsible for acting on such
              guidance and that Rightangled Limited accepts no liability in the
              event you choose not to do so.
            </li>
          </ol>

          <div className="mb-4 lg-text black-text">Contacting us</div>
          <ol type="1" className="number-list sm-text">
            <li>
              If you wish to contact us for any reason, including canceling a
              contract or to make a complaint, you can contact us by telephoning
              our customer service team on 020 3950 3394 or by emailing us on
              info@rightangled.co
            </li>
            <li>
              If we need to contact you we will do so by e-mail, phone or
              occasionally by letter using the contact details you provide to us
              in your order. By providing us with your contact details you give
              us your consent to contact you about the service we provide and
              Products you have bought or enquired about. If necessary we may
              leave you a message.
            </li>
            <li>
              We will send test results to you by email unless you expressly ask
              us not to do so. It is your responsibility to ensure that your
              contact details are kept up-to-date.
            </li>
            <li>
              Rightangled Limited is not liable if personal or medical data
              transmitted via an email is received by an unauthorised third
              party, as both parties (Rightangled Limited & the customer) agree
              that internet is an environment that cannot guarantee
              confidentiality.
            </li>
          </ol>
          <div className="mb-4 lg-text black-text">Our service</div>
          <ol type="1" className="number-list sm-text">
            <li>
              All samples include a prepaid box to be sent on to one of our
              laboratories. It is your responsibility to ensure the guidance of
              how to undertake the test correctly.
            </li>
            <li>
              Kits must be used within 48 hours of receipt and posted on to the
              laboratory whose address can be found on the pre-paid box which is
              included in the kit.
            </li>
            <li>
              The test results and any accompanying interpretation will be sent
              to you by email unless you expressly ask us not to do so.
            </li>
            <li>
              A printed copy of the results can be ordered and posted for an
              additional charge during the process.
            </li>
            <li>
              We may contact you by telephone regarding your sample and test
              results if necessary
            </li>
            <li>
              You must also provide us with certain personal information,
              including your name, address, gender and date of birth in order to
              purchase our service(s). Name and date of birth are standard means
              of identifying biological samples at our partner laboratories.
            </li>
            <li>
              The tests sold by Rightangled Limited are specialist pathology
              tests. You agree that you are solely responsible for selecting
              tests and that any tests you do purchase from Rightangled Limited
              are suitable for you and their intended purpose. If in doubt
              please talk to your doctor, a qualified medical professional, your
              occupational health advisor, employer or person advising you to
              have the test.
            </li>
          </ol>
          <div className="mb-4 lg-text black-text">
            Medical questionnaire and how we use your personal information
          </div>
          <ol type="1" className="number-list sm-text">
            <li>
              Except as set out below, any information that is collected from
              the medical questionnaire and/or the test results will be used
              solely in relation to the products you have purchased from us.
            </li>
            <li>
              By placing an order you give us your consent to pass any and all
              relevant information about you on to our partner clinics and
              laboratories and/or to our employees and agents for the purposes
              of preparing your sample and analyzing and interpreting the
              results.
            </li>
            <li>
              We may use your information for our own business analysis, product
              development and marketing purposes.
            </li>
            <li>
              We will not sell or pass on your personal information to third
              parties to enable them to contact you directly or market their
              products or services to you.
            </li>
            <li>
              We only use your personal information in accordance with our
              Privacy Policy.
            </li>
            <li>
              Covid-19 has been added by the Government to ‘the list of
              notifiable diseases’ and as such Rightangled Limited and/or our
              partner laboratories have a statutory duty to notify the ‘proper
              office’ at your local council health protection team of suspected
              cases of certain infectious diseases.
            </li>
            <li>
              You must ensure that you follow the latest Government Advice
              regarding Covid-19 available at
              https://www.gov.uk/government/topical-events/corona
              virus-covid-19-uk-government-response?utm_campaign=
            </li>
            <li>
              515394_MAND-O3-VEVA-124629-0403-VEVA-130944-0403%20coronavirus%20information&utm_medium=email&utm_source=
            </li>
            <li>Dotmailer&dm_i=4Y1G,B1OI,1GIU88,17MR4,1</li>

            <li>
              You must also provide us with certain personal information,
              including your name, address, gender and date of birth in order to
              purchase our service(s). Name and date of birth are standard means
              of identifying biological samples at our partner laboratories.
            </li>

            <li>
              The tests sold by Rightangled Limited are specialist pathology
              tests. You agree that you are solely responsible for selecting
              tests and that any tests you do purchase from Rightangled Limited
              are suitable for you and their intended purpose. If in doubt
              please talk to your doctor, a qualified medical professional, your
              occupational health advisor, employer or person advising you to
              have the test.
            </li>

            <li>How the contract is formed between you and us</li>

            <li>
              Payment must be received in full in cleared funds prior to
              dispatch of goods.
            </li>

            <li>
              Once payment is received, we will confirm our acceptance to you by
              sending you confirmation email ("Acceptance Confirmation"). The
              contract between us will only be formed on the date we send you
              the Acceptance Confirmation (the "Contract").
            </li>
            <li>
              If, for any reason, we are unable to perform a test you have
              ordered, we will inform you by email or by phone.
            </li>

            <li>
              If you have already paid for the test we will refund you the full
              amount within 3 working days. A working day is any day other than
              a Saturday, Sunday or public holiday in the UK ("Working Day").
            </li>
            <li>
              By purchasing a test and sending a sample to one of our
              laboratories you are giving Rightangled Limited and its partner
              laboratories your informed consent to perform the tests you have
              ordered.
            </li>

            <li></li>
          </ol>
          <div className="mb-4 lg-text black-text">How to pay</div>
          <ol type="1" className="number-list sm-text">
            <li>
              You may pay for Products using a debit or credit card and this
              includes MasterCard, Visa, Visa Electron, Switch, Solo, Maestro or
              JCB.
            </li>
          </ol>
          <div className="mb-4 lg-text black-text">Price of products</div>
          <ol type="1" className="number-list sm-text">
            <li>
              The price of the Products will be as quoted on our website at the
              time you submit your order.
            </li>
            <li>
              You will pay an additional charge if you choose any extra tests
              other than those initially requested prior to completing your
              appointment.
            </li>
            <li>
              Prices for our Products may change from time to time, but changes
              will not affect any order you have already placed.
            </li>
            <li>
              After your payment has been processed and will receive an order
              confirmation.
            </li>
          </ol>
          <div className="mb-4 lg-text black-text">
            Samples and test results
          </div>
          <ol type="1" className="number-list sm-text">
            <li>
              Any sample you provide (whether it is blood, tissue, bodily fluid,
              or other biological sample) to one of our laboratories for
              analysis will be analysed only in relation to the test or tests
              that you have ordered.
            </li>
            <li>
              Rightangled Limited will not be responsible for any samples that
              the laboratory are unable to process due to errors in the method
              of collection or in following any of the instructions provided.
            </li>
            <li>Further charges would apply if a retest is ordered.</li>
          </ol>
          <div className="mb-4 lg-text black-text">
            Cancellations and refungs
          </div>
          <ol type="1" className="number-list sm-text">
            <li>
              If you would like to cancel your order please contact our customer
              care team on 020 3950 3394 between 9am and 6pm Monday – Friday.
            </li>

            <li>You must have your customer and payment details to hand.</li>
            <li>
              If you cancel your order before your sample collection kit has
              been dispatched then you will receive a full refund within 7-10
              working days.
            </li>

            <li>
              We will refund you on the credit card or debit card used by you to
              pay.
            </li>
            <li>No refunds can be given once the kit has been dispatched.</li>
          </ol>
          <div className="mb-4 lg-text black-text">Our liability</div>
          <ol type="1" className="number-list sm-text">
            <li>
              The service(s) you purchase from us are for private use only and
              you agree not to use the Products for any commercial purpose. We
              are not liable to you for any loss of profit, loss of business or
              revenue, business interruption or loss of business opportunity.
            </li>
            <li>
              Our total liability to you resulting from a Contract is limited to
              the total value of that Contract - meaning the price you paid for
              the Products and any additional services. In particular we are not
              liable for any costs, loss, delay, inconvenience or damage you
              suffer as a result of:
            </li>

            <li>The sample being lost, delayed or damaged in transit;</li>
            <li>
              The laboratory being unable to test your sample for any reason;
            </li>
            <li>
              Test results not being made available to you within the turnaround
              time stated for any product;
            </li>
            <li>
              Your failure to follow instructions provided to you in relation to
              sample collection or presample preparation requirements.
            </li>
            <li>
              Your failure to act upon our advice if we recommend that you seek
              medical advice or attention having taken a test;
            </li>
            <li>
              Your failure to show positive tests results to the relevant health
              authorities.
            </li>
            <li>Your failure to attend a pre-booked appointment;</li>
            <li>Loss or damage that is not foreseeable;</li>
            <li>Events outside our control as described below.</li>

            <li>
              If we fail to comply with the Terms, we are responsible for loss
              or damage you suffer that is a foreseeable result of our breach of
              the Terms or our negligence. Loss or damage is foreseeable if it
              is an obvious consequence of our breach or if it was contemplated
              by you and us at the time we entered into this Contract.
            </li>
            <li>
              It is your responsibility to ensure that the relevant sample has
              been collected.
            </li>
            <li>
              We do not in any way exclude or limit our liability for: death or
              personal injury caused by our negligence; fraud or fraudulent
              misrepresentation; any breach of the terms implied by section 12
              of the Sale of Goods Act 1979 (title and quiet possession) as
              amended by the Consumer Rights Act 2015; ch of the terms implied
              by section 9 to 11 of the Consumer Rights Act 2015 (description,
              satisfactory quality, fitness for purpose and samples); and
              defective products under the Consumer Protection Act 1987.
            </li>
          </ol>
          <div className="mb-4 lg-text black-text">
            Events outside our control
          </div>
          <ol type="1" className="number-list sm-text">
            <li>
              We will not be liable or responsible for any failure to perform,
              or delay in performance of, any of our obligations under a
              Contract that is caused by any act or event beyond our reasonable
              control (an "Event outside Our Control").
            </li>
            <li>
              If an Event Outside Our Control takes place that affects the
              performance of our obligations under a Contract: we will contact
              you as soon as reasonably possible to notify you; and our
              obligations under a Contract will be suspended and the time for
              performance of our obligations will be extended for the duration
              of the Event outside Our Control.
            </li>
            <li>
              You may cancel a Contract affected by an Event Outside Our Control
              which has continued for more than 30 days. To cancel, please
              contact us. If you opt to cancel, we will refund in full the total
              price you have paid for the Contract.
            </li>
          </ol>
          <div className="mb-4 lg-text black-text">
            Our right to vary these terms
          </div>
          <ol type="1" className="number-list sm-text">
            <li>We may amend the Terms from time to time</li>
            <li>
              Every time you order a service from us, the Terms in force at the
              time of placing your order will apply to the Contract between you
              and us.
            </li>
            <li>
              If we have to revise the Terms as they apply to your order, we
              will contact you to give you reasonable advance notice of the
              changes and what orders will be affected. We will let you know how
              to cancel the Contract if you are not happy with the changes.
            </li>
          </ol>
          <div className="mb-4 lg-text black-text">Other important terms</div>
          <ol type="1" className="number-list sm-text">
            <li>
              We may transfer our rights and obligations under a Contract to
              another organization, but this will not affect your rights or our
              obligations under the Terms.
            </li>
            <li>
              This Contract is between you and us. No other person shall have
              any rights to enforce any of its terms, whether under the
              Contracts (Rights of Third Parties) Act 1999 or otherwise.
            </li>
            <li>
              Each of the paragraphs of the Terms operates separately. If any
              court or relevant authority decides that any of them are unlawful
              or unenforceable, the remaining paragraphs will remain in full
              force and effect.
            </li>
            <li>
              If we fail to insist that you perform any of your obligations
              under the Terms, or if we do not enforce our rights against you,
              or if we delay in doing so, that will not mean that we have waived
              our rights against you and will not mean that you do not have to
              comply with those obligations. If we do waive a default by you, we
              will only do so in writing, and that will not mean that we will
              automatically waive any later default by you.
            </li>
            <li>
              Please note that the Terms are governed by English law. This means
              a Contract for the purchase of Products through our site and any
              dispute or claim arising out of or in connection with it will be
              governed by English law. You and we both agree to that the courts
              of England and Wales will have exclusive jurisdiction.
            </li>
          </ol>
        </div>
      </div>
      <div className="step-footer">
        <BtnMain
          text="Edit"
          className="btn gray-line-btn me-4 fixWidth-btn"
          type="button"
        />
        <BtnMain
          text="Sign Prescription"
          className="btn btn-success "
          type="button"
        />
      </div>
    </div>
  );
}
export default Step3;
