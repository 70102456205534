import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../store/Actions";
import { useFormik } from "formik";
import { resetPasswordSchema } from "../../utils/schema";
import Layout from "../../components/Layout/Layout";
import { Link } from "react-router-dom";
import { Form, FormGroup, Container } from "react-bootstrap";
import TextField from "../../components/inputs/textfield";
import BtnMain from "../../components/buttons/btn_main";
import Loader from "../../components/Layout/loader/Loader";
import ImgText from "../../components/img-text/ImgText";
const queryString = require("query-string");

function ResetPassword(props) {
  const url = props.location.search;
  let params = queryString.parse(url);
  const isLoading = useSelector((state) => state?.ui?.loaders?.reset_password);
  const [error, setError] = useState("");
  const [successMsg, setSuccess] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  const toggleConfirmPasswordVisiblity = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: params.email,
      password: "",
      confirmPassword: "",
      token: params.token,
    },
    onSubmit(values, { resetForm }) {
      const body = values;
      dispatch(resetPassword(body, "reset_password")).then((res) => {
        if (res.success) {
          setError("");
          setSuccess(true);
          resetForm({ values: "" });
        } else {
          setError(res.errorMessage);
          setSuccess(false);
        }
      });
    },
    validationSchema: resetPasswordSchema,
  });

  /******************* 
    @Purpose : Used for Handle Form Submit 
    @Parameter : {e}
    @Author : INIC
  *******************/
  const handleSubmit = (e) => {
    e.preventDefault();
    formik.submitForm();
  };

  /******************* 
@purpose : Render Reset Password Page UI 
@Author  : INIC
******************/

  return (
    <Layout>
      {isLoading && <Loader text="Loading" />}
      <Container className="auth-main">
        <div className="auth-box">
          <div className="auth-heading">Reset password</div>
          <div className="auth-subheading sm-text">
            Please set the new password
          </div>
          <div className="form-wrap">
            <Form onSubmit={handleSubmit} noValidate>
              <FormGroup className="form-group input-ic-wrap">
                <TextField
                  type={passwordShown ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  formik={formik}
                  id="password"
                  showUntoucherError
                />
                <i
                  className={`${
                    passwordShown ? "icon-eye-slash" : "icon-eye"
                  } toggle-password`}
                  aria-hidden="true"
                  onClick={togglePasswordVisiblity}
                ></i>
              </FormGroup>
              <FormGroup className="form-group input-ic-wrap">
                <TextField
                  type={confirmPasswordShown ? "text" : "password"}
                  placeholder="Confirm password"
                  name="confirmPassword"
                  formik={formik}
                  id="cpassword"
                  showUntoucherError
                />
                <i
                  className={`${
                    confirmPasswordShown ? "icon-eye-slash" : "icon-eye"
                  } toggle-password`}
                  aria-hidden="true"
                  onClick={toggleConfirmPasswordVisiblity}
                ></i>
              </FormGroup>

              <FormGroup className="form-group text-center mb-0">
                <BtnMain
                  text="Confirm"
                  className={
                    !(formik.isValid && formik.dirty)
                      ? "btn btn-primary w-100 password-btn disabled-btn"
                      : "password-btn btn btn-primary w-100"
                  }
                  type="submit"
                />
                <Link to="/login" className="back-login sm-text">
                  Back to Sign in
                </Link>
              </FormGroup>
            </Form>
            {error && (
              <div className="login-block ">
               
                <ImgText
                  userBlock={"user-block-sm pe-0 align-items-start"}
                  userIc={"danger-bg"}
                  userIcon={"icon-times-circle white-text"}
                >
                  <div className="sm-text light-black-text user-textHeading">
                    {error}
                  </div>
                </ImgText>
              </div>
            )}
            {successMsg && (
              <div className="login-block mb-3 pb-3">
               
                <ImgText
                  userBlock={"user-block-sm pe-0 align-items-start"}
                  userIc={"success-bg"}
                  userIcon={"icon-check-circle white-text"}
                >
                  <div className="sm-text light-black-text user-textHeading">
                    You have successfully Reset Password on Rightangle. Click to{" "}
                    <Link to={"/login"} className="b-700">
                      Login.
                    </Link>
                    <div></div>
                  </div>
                </ImgText>
              </div>
            )}
          </div>
        </div>
      </Container>
    </Layout>
  );
}
export default ResetPassword;
