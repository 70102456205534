import React, { useState } from "react";
import { Row, Col, FormGroup } from "react-bootstrap";
import BtnMain from "../../../components/buttons/btn_main";
import { Link } from "react-router-dom";
import Layout from "../../../components/Layout/dashboard-layout/Layout";
import { useFormik } from "formik";
import TextField from "../../../components/inputs/textfield";
import StepWizard from "react-step-wizard";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import Step3 from "./steps/Step3";
import Step4 from "./steps/Step4";
import Step5 from "./steps/Step5";
function RegisterKitAdd(_props) {
  const [stepOpen, setStepOpen] = useState(false);
  const stepHandler = () => {
    setStepOpen((stepOpen) => !stepOpen);
  };

  /******************* 
@purpose : used for form Handle
@Parameter : {e} 
@Author : INIC
******************/

  const formik = useFormik({
    initialValues: {
      selectProfession: "",
      registration: "",
    },
    onSubmit(_values) {
     
    },
  });
  const onStepChange = (stats) => {
    return stats;
  };

  /******************* 
@Purpose : Used for render RegisterKitAdd UI
@Parameter : {}
@Author : INIC
******************/

  return (
    <Layout>
      <div className="dashboard-inner-content dashboard-wrap pb-5">
        <div className="big-heading h3">Kit Registration </div>
        <div className="idea-box align-items-start mb-4 ">
          <div className="idea-ic">
            <i className="icon-info-circle"></i>
          </div>
          <div className="idea-text">
            <div className="idea-heading b-700 lg-text mb-2">
              Enter the bar code as it appears on your kit.
            </div>
            <div className="idea-subheading text-size-455">
              <div> Important Note:</div>
              Here you can register multiple tests for yourself, only. Please
              use a{" "}
              <Link to={"/"} className="underline-anchor  b-700">
                new account
              </Link>{" "}
              for registering new kits for other individuals.
            </div>
          </div>
        </div>
        <Row>
          <Col xs={12} md={5} className="pt-2">
            <FormGroup className="form-group mb-4 pb-3">
              <div className="label-heading xs-text b-700 mb-2 pt-4">
                Enter the bar code as it appears on your kit.{" "}
              </div>
              <TextField
                type="text"
                placeholder="xxxx-xxxx-xxxx-xxxx"
                name="EmailAddress"
                formik={formik}
                className="search-input-wrap"
                aria-label="Emailaddress"
                showUntoucherError
              />
            </FormGroup>
            <BtnMain
              text="Continue"
              className="btn btn-primary LgfixWidth-btn "
              type="submit"
            >
              {" "}
            </BtnMain>
            <div
              className="grey-text cursor-pointer mt-4 pt-3 lg-text d-block"
              onClick={stepHandler}
            >
              Don’t have a kit? Get one here.
            </div>
          </Col>
        </Row>
        {stepOpen ? (
          <div className="step-section">
            <StepWizard onStepChange={onStepChange} initialStep={1}>
              <Step1 props={stepHandler} />
              <Step2 props={stepHandler} />
              <Step3 props={stepHandler} />
              <Step4 props={stepHandler} />
              <Step5 props={stepHandler} />
            </StepWizard>
          </div>
        ) : (
          ""
        )}
         <div></div>
      </div>
    </Layout>
  );
}
export default RegisterKitAdd;
