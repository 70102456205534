import React , {Suspense}from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store";
import {  get_cookie } from "./utils/Functions";
import {BrowserRouter as Router,Route,Switch,Redirect,} from "react-router-dom";
// Authentication pages
// 404 page
import FourZeroFour from "./pages/FourZeroFour/FourZeroFour";
// Offline page
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// components
import Welcome from "./pages/Welcome";
//notification library
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import "bootstrap/dist/css/bootstrap.css";
import "react-datepicker/dist/react-datepicker.css";
import './assets/scss/style.scss';
import Login from "./pages/Auth/Login";
import Register from "./pages/Auth/register/Register";
import ResetPassword from "./pages/Auth/ResetPassword";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ClientProfile from "./pages/client-profile/ClientProfile";
import PasswordReset from "./pages/client-profile/PasswordReset";
import DashBoard from "./pages/dashboard/DashBoard";
import Wallates from "./pages/client-profile/Wallates";
import Notification from "./pages/client-profile/Notification";
import DoctorAccess from "./pages/client-profile/DoctorAccess";
import OrdersKits from "./pages/client-profile/OrdersKits";
import Subscription from "./pages/client-profile/Subscription";
import EmailInvitation from "./pages/client-profile/EmailInvitation";
import FamilyMember from "./pages/client-profile/FamilyMember";
import FamilyMemberChild from "./pages/client-profile/FamilyMemberChild";
import RegisterKitList from "./pages/register-kit/register-kit-list/RegisterKitList";
import RegisterKitAdd from "./pages/register-kit/register-kit-add/RegisterKitAdd";
// import RegisterKitAdd from "./pages/register-kit/register-kit-add";
/******************* 
@Purpose : This page is default page for our project
@Parameter : {}
@Author : INIC
******************/
function App(props) {
  /******************* 
  @Purpose : Used for token authorization
  @Parameter : {}
  @Author : INIC
  ******************/
  const Authorization = () => {
    return get_cookie("accessToken") ? true : false;
  };
  /******************* 
  @Purpose : Used for private routing
  @Parameter : {Component1, ...rest}
  @Author : INIC
  ******************/
  const PrivateRoute = ({ component: Component1, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        Authorization() ? <Component1 {...props} /> : <Redirect to="/" />
      }
    />
  );
  /******************* 
  @Purpose : Used for check user is already login or not
  @Parameter : {Component2, ...rest }
  @Author : INIC
  ******************/
  const LoginCheckRoute = ({ component: Component2, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        Authorization() ? (
          <Redirect to="/dashboard" />
        ) : (
          <Component2 {...props}  />
        )
      }
    />
  );
  /******************* 
  @Purpose : Used for check reset password
  @Parameter : {Component3, ...rest}
  @Author : INIC
  ******************/
  const ResetPasswordCheckRoute = ({ component: Component3, ...rest }) => (
    <Route
      {...rest}
      render={(props) =>
        Authorization() ? (
          <Redirect to="/dashboard" />
        ) : (
          <Component3 {...props} />
        )
      }
    />
  );
  return (
    <Suspense fallback="loading">
      <Provider store={store}>
       
      
      <PersistGate loading={null} persistor={persistor}>
            <Router>
              <div style={{ textAlign: "center" }}>
                <ToastContainer />
              </div>
              <Switch>
                <LoginCheckRoute exact path="/" component={Welcome} welcomePage={true} />
                <LoginCheckRoute exact path="/login" component={Login} />
                <LoginCheckRoute exact path="/register" component={Register}  />
                <ResetPasswordCheckRoute exact path="/reset-password" component={ResetPassword} />
                <LoginCheckRoute exact path="/forgot-password" component={ForgotPassword} />
                <PrivateRoute exact path="/dashboard" component={DashBoard} />
                <PrivateRoute exact path="/client-profile" component={ClientProfile} />
                <PrivateRoute exact path="/change-password" component={PasswordReset} />
                <PrivateRoute exact path="/wallates" component={Wallates} />
                <PrivateRoute exact path="/notification" component={Notification} />
                <PrivateRoute exact path="/doctor-access" component={DoctorAccess} />
                <PrivateRoute exact path="/orders-kits" component={OrdersKits} />
                <PrivateRoute exact path="/subscription" component={Subscription} />
                <PrivateRoute exact path="/family-member" component={FamilyMember} />
                <PrivateRoute exact path="/register-kit-list" component={RegisterKitList} />
                <PrivateRoute exact path="/register-kit-add" component={RegisterKitAdd} />
                <PrivateRoute exact path="/family-member-child" component={FamilyMemberChild} />
                <PrivateRoute exact path="/email-invitation" component={EmailInvitation} />
                <Route path="/*" component={FourZeroFour} />
              </Switch>
            </Router>
            </PersistGate>
      </Provider>
    </Suspense>
  );
}

export default App;

