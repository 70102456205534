import React from "react";
import Step1 from "../../../components/register/Step1";
import StepWizard from "react-step-wizard";

function Register() {
  /******************* 
@Purpose : Used for render Register UI
@Parameter : {}
@Author : INIC
******************/

  const onStepChange = (stats) => {
    return stats;
  };

  return (
    <div>
      <StepWizard onStepChange={onStepChange} initialStep={1}>
        <Step1 />
      </StepWizard>
    </div>
  );
}
export default Register;
