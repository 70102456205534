import React from "react";
import * as yup from "yup";
import EM from "./ErrorMessages";
import { validateEmail, validatePassword } from "./Functions";
/******************* 
@purpose :Signup Page Validation Schema
@Author : INIC
******************/
export const signupSchema = yup.object().shape({
  title: yup.string().trim().required(EM.REQUIRED),
  firstName: yup.string().trim().required(EM.REQUIRED),
  lastName: yup.string().trim().required(EM.REQUIRED),
  birthdate: yup.string().trim().required(EM.REQUIRED),
  email: yup
    .string()
    .trim()
    .required(EM.PROVIDE_EMAIL)
    .matches(validateEmail, EM.PROVIDE_VALID_MO_EMAIL),
  password: yup
    .string()
    .trim()
    .required(EM.PROVIDE_PASSWORD)
    .matches(validatePassword, EM.PROVIDE_VALID_PASSWORD),
  confirmPassword: yup
    .string()
    .trim()
    .required(EM.PROVIDE_PASSWORD)
    .oneOf([yup.ref("password"), null], EM.PASSWORD_NOT_MATCHED),
  // acceptTerms: yup.bool().oneOf([true], 'Accept Terms & Conditions is required'),
});
/******************* 
@purpose : Login Page Validation Schema
@Author : INIC
******************/
export const loginSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required(EM.PROVIDE_EMAIL)
    .matches(validateEmail, EM.PROVIDE_VALID_MO_EMAIL),
  password: yup.string().trim().required(EM.PROVIDE_PASSWORD),
  // .matches(validatePassword, EM.PROVIDE_VALID_PASSWORD),
});
/******************* 
@purpose : Forgot Password Validation Schema
@Author : INIC
******************/
export const forgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .trim()
    .required(EM.PROVIDE_EMAIL)
    .matches(validateEmail, EM.PROVIDE_VALID_MO_EMAIL),
});
/******************* 
@purpose : Reset Password Validation Schema
@Author : INIC
******************/
export const resetPasswordSchema = yup.object().shape({
  password: yup
    .string()
    .trim()
    .required(EM.PROVIDE_PASSWORD)
    .matches(validatePassword, EM.PROVIDE_VALID_PASSWORD),
  confirmPassword: yup
    .string()
    .trim()
    .required(EM.PROVIDE_PASSWORD)
    .oneOf([yup.ref("password"), null], EM.PASSWORD_NOT_MATCHED),
});

export const verifyUserSchema = yup.object().shape({
  id_verify: yup.bool().oneOf([true], "Id verification is required"),
});

/******************* 
@purpose :Change Password Page Validation Schema
@Author : INIC
******************/
export const changePasswordSchema = yup.object().shape({
  oldPassword: yup.string().trim().required(EM.PROVIDE_PASSWORD),
  // .matches(validatePassword, EM.PROVIDE_VALID_PASSWORD),
  newPassword: yup
    .string()
    .trim()
    .required(EM.PROVIDE_PASSWORD)
    .matches(validatePassword, EM.PROVIDE_VALID_PASSWORD),
  confirmNewPassword: yup
    .string()
    .trim()
    .required(EM.PROVIDE_PASSWORD)
    .oneOf([yup.ref("newPassword"), null], EM.PASSWORD_NOT_MATCHED),
});

/******************* 
@purpose :Profile page Validation Schema
@Author : INIC
******************/
export const profileSchema = yup.object().shape({
  Title: yup.string().trim().required(EM.REQUIRED),
  FirstName: yup.string().trim().required(EM.REQUIRED),
  LastName: yup.string().trim().required(EM.REQUIRED),
  Email: yup
    .string()
    .trim()
    .required(EM.PROVIDE_EMAIL)
    .matches(validateEmail, EM.PROVIDE_VALID_MO_EMAIL),
  State: yup.string().required(EM.REQUIRED),
  birthdate: yup.string().trim().required(EM.REQUIRED),
  
});
