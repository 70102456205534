import React from "react";
import { Row, Col, FormGroup, Form } from "react-bootstrap";
import BtnMain from "../../components/buttons/btn_main";
import Layout from "../../components/Layout/dashboard-layout/Layout";
function Notification(_props) {

 
  /******************* 
@Purpose : Used for render Notification UI
@Parameter : {}
@Author : INIC
******************/

  return (
    <Layout>
      <div className="dashboard-inner-content dashboard-wrap pb-5">
        <div className="big-heading h3">Notification</div>
        <div className="idea-box align-items-center box-size-478 mb-4">
          <div className="idea-ic">
            <i className="icon-info-circle"></i>
          </div>
          <div className="idea-text">
            <div className="idea-subheading">
              Please tick the boxes of the information you would like to
              receive.
            </div>
          </div>
        </div>
        <Row>
          <Col md={3} sm={6} xs={12} className="">
            <FormGroup className="form-group pb-3 ">
              <Form.Check
                type={"checkbox"}
                id={`policy`}
                name="policyRulesAgreed"
              >
                <Form.Check.Input type={"checkbox"} isValid />
                <Form.Check.Label>Invoice available</Form.Check.Label>
              </Form.Check>
            </FormGroup>
          </Col>
          <Col md={3} sm={6} xs={12} className="">
            <FormGroup className="form-group pb-3 ">
              <Form.Check
                type={"checkbox"}
                id={`policy`}
                name="policyRulesAgreed"
              >
                <Form.Check.Input type={"checkbox"} isValid />
                <Form.Check.Label>Marketing emails</Form.Check.Label>
              </Form.Check>
            </FormGroup>
          </Col>
          <Col md={3} sm={6} xs={12} className="">
            <FormGroup className="form-group pb-3 ">
              <Form.Check
                type={"checkbox"}
                id={`policy`}
                name="policyRulesAgreed"
              >
                <Form.Check.Input type={"checkbox"} isValid />
                <Form.Check.Label>New test available</Form.Check.Label>
              </Form.Check>
            </FormGroup>
          </Col>
          <Col md={3} sm={6} xs={12} className="d-none d-md-block"></Col>
          <Col md={3} sm={6} xs={12} className="">
            <FormGroup className="form-group pb-3 ">
              <Form.Check
                type={"checkbox"}
                id={`policy`}
                name="policyRulesAgreed"
              >
                <Form.Check.Input type={"checkbox"} isValid />
                <Form.Check.Label>Reminder for update</Form.Check.Label>
              </Form.Check>
            </FormGroup>
          </Col>
          <Col md={3} sm={6} xs={12} className="">
            <FormGroup className="form-group pb-3 ">
              <Form.Check
                type={"checkbox"}
                id={`policy`}
                name="policyRulesAgreed"
              >
                <Form.Check.Input type={"checkbox"} isValid />
                <Form.Check.Label>Report updates</Form.Check.Label>
              </Form.Check>
            </FormGroup>
          </Col>
          <Col xs={12} className="mt-1">
            <BtnMain
              text="Save Changes"
              className="btn btn-primary  lg-btn"
              type="submit"
            >
              {" "}
            </BtnMain>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}
export default Notification;
