import { post,formdata} from '../../api'; // API Types
import Routes from '../../api/Routes'; // API endpoint routes
import { alertDialogue, set_cookie } from '../../utils';
import {setUserInfo} from "./User"
const {
    LOGIN,
    REGISTER,
    GOOGLEREGISTER,
    FORGOT,
    RESETPASSWORD,
    REGISTERVERIFY,
    CHANGEPASSWORD,
    ProfileData,
} = Routes;


/******************** 
@purpose : Used for login user
@Parameter : { data, dispatch }
@Author : INIC
******************/
export const login = (data,loader) => (dispatch) => {
    let payload = {
        email: data.email,
        password: data.password,
        User_Type: "Patient",
    };
    return post(LOGIN, payload,loader).then((res) => {
        if (res.success)
         {
             set_cookie('accessToken',res.jwtToken, 1 );
             dispatch(setUserInfo(res));
        }
        return res;
    });
};


/******************** 
@purpose : Used for register 
@Parameter : { data }
@Author : INIC
******************/
export const register = (data,loader ) => (_dispatch) => {
   
       
    return post(REGISTER, data, loader).then((res) => {
       
               
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        return res;
    });
};

export const googleLogin = (data,loader ) => (_dispatch) => {
   
    
    return post(GOOGLEREGISTER, data, loader).then((res) => {
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        return res;
    });
};

/******************** 
@purpose : Used for Forgot Password 
@Parameter : { data }
@Author : INIC
******************/
export const forgotPassword = (data,loader ) => (_dispatch) => {
      
    return post(FORGOT, data, loader).then((res) => {
      
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        return res;
    });
};

/******************** 
@purpose : Used for Reset Password 
@Parameter : { data }
@Author : INIC
******************/
export const resetPassword = (data,loader ) => (_dispatch) => {
      
    return post(RESETPASSWORD, data, loader).then((res) => {
      
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        return res;
    });
};


/******************** 
@purpose : Used for register 
@Parameter : { data }
@Author : INIC
******************/
export const registerVerify = (data,loader ) => (_dispatch) => {
   
    data.device_type = 'web';
    
   
    return post(REGISTERVERIFY, data, loader).then((res) => {
       
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        return res;
    });
};

/******************** 
@purpose : Used for Reset Password 
@Parameter : { data }
@Author : INIC
******************/
export const changePassword = (data,loader ) => (_dispatch) => {
       
    return post(CHANGEPASSWORD, data, loader).then((res) => {
             
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        return res;
    });
};


/******************** 
@purpose : Used for Profile Data to api 
@Parameter : { data }
@Author : INIC
******************/
export const sendProfileData = (data,loader ) => (_dispatch) => {
   
    return formdata(ProfileData, data, loader).then((res) => {
         
        if (res && res.settings && res.settings.status === 0) {
            alertDialogue(res.settings.message);
        }
        return res;
    });
};