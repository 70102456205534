import * as types from './Types'; // Redux actions payload types

/******************** 
@purpose : Used for store user Info
@Parameter : { }
@Author : INIC
******************/
export const setUserInfo = (data) => ({
    type: types.SET_USER_INFO,
    data,
});
/******************** 
@purpose : Used for store Updated User Info
@Parameter : { }
@Author : INIC
******************/
export const setUserInfoUpdated = (data) => ({
    type: types.SET_USER_INFO_UPDATED,
    data,
});
