import React from "react";
import { Form, FormGroup, Row, Col, Breadcrumb } from "react-bootstrap";
import { useSelector } from "react-redux";
import BtnMain from "../../components/buttons/btn_main";
import Loader from "../../components/Layout/loader/Loader";
import TextField from "../../components/inputs/textfield";
import Layout from "../../components/Layout/dashboard-layout/Layout";

function EmailInvitation(props) {
  
  const isLoading = useSelector((state) => state?.ui?.loaders?.change_password);
 
 
 

  /******************* 
@purpose : used for Form Handle
@Parameter : {e} 
@Author : INIC
******************/
  const handleSubmit = (e) => {
    e.preventDefault();
   
  };
  /******************* 
@purpose : Render Email connection invite UI 
@Author  : INIC
******************/

  return (
    <Layout>
      {isLoading && <Loader text="Loading" />}
      <div className="dashboard-inner-content">
        <Breadcrumb>
          <Breadcrumb.Item href="/client-profile" className="sm-text ">
            Profile<span className="icon-arrow-right"></span>{" "}
          </Breadcrumb.Item>
          <Breadcrumb.Item active className="sm-text">
            Invite Family Member
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="big-heading h3 mb-3">Email connection invite</div>
        <div className="grey-text lg-text b-400 mb-5 pb-2 email-main-subheading">
          All family members who are over the age of 18 will need to create
          their own Rightangled account. You can invite family members to
          connect below.
        </div>
        <Row>
          <Col xs={12} md={5} className="mb-4">
            <FormGroup className="form-group input-ic-wrap">
              <TextField
                type="text"
                placeholder="Email address"
                name="EmailAddress"
                className="search-input-wrap"
                aria-label="Emailaddress"
                showUntoucherError
              />
            </FormGroup>
            <BtnMain
              text="Send email"
              className="btn btn-primary  "
              type="submit"
            >
              {" "}
            </BtnMain>
          </Col>
        </Row>

        <Form onSubmit={handleSubmit} noValidate></Form>
        <div className="idea-box align-items-center  m-0">
          <div className="idea-ic">
            <i className="icon-info-circle"></i>
          </div>
          <div className="idea-text">
            <div className="idea-subheading">
              An email wiill be sent to your family member. They will need to
              confirm that they would like to be added as a member of your
              family.
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default EmailInvitation;
