import React from "react";
import {Tab, Tabs } from "react-bootstrap";
import Layout from "../../components/Layout/dashboard-layout/Layout";
import BtnMain from "../../components/buttons/btn_main";
import ImgText from "../../components/img-text/ImgText";
function Subscription(_props) {

  /******************* 
@purpose : Render Subscription UI 
@Author  : INIC
******************/

  return (
    <Layout>
      <div className="dashboard-inner-content pb-5">
        <div className="big-heading h3">Your Subscription</div>
        <div className="idea-box align-items-center mb-4">
          <div className="idea-ic">
            <i className="icon-info-circle"></i>
          </div>
          <div className="idea-text">
            <div className="idea-subheading">
              You have no subscription in past
            </div>
          </div>
        </div>
        <Tabs defaultActiveKey="Subscription" className="">
          <Tab eventKey="Subscription" title="Personalised Subscription">
            <div className="subscription-wrap">
              <div className="subscriptionInfo pe-md-2">
                <div className="subscriptionInfo-heading xs-text mb-3 black-text b-700">
                  Additional tests
                </div>
                <div className="subscriptionInfo-block ">
                  <div className="subscriptionInfo-tile mb-3">
                    <div className="subscriptionInfo-body ">
                      <ImgText
                        userBlock={"user-block-sm align-items-start"}
                        userIc={"primary-bg"}
                        userText={"d-lg-flex justify-content-between"}
                        userIcon={"icon-tablets white-text"}
                      >
                        <div className="user-text-inner me-2">
                          <div className="lg-text light-black-text b-700 user-textHeading">
                            Thyroid Stimulating Hormone (TSH)
                          </div>
                          <div className="singl-tag-tile success-light-bg mt-2">
                            Strong match
                          </div>
                        </div>
                        <div className="text-nowrap mt-lg-0 mt-2 sm-text primary-text lg-text b-700">
                          ￡50.00
                        </div>
                      </ImgText>
                      <div className="fst-italic grey-text xs-text mt-3">
                        Recommended for you based on the focus of:
                        <div className="singl-tag-tile success-lightest-bg success-dark-text ms-3 fst-normal">
                          ENERGY
                        </div>
                      </div>
                    </div>
                    <div className="subscriptionInfo-footer text-end p-3 ">
                      <BtnMain
                        text="Remove"
                        className="btn btn-danger-light LgfixWidth-btn "
                        type="button"
                      />
                    </div>
                  </div>
                  <div className="subscriptionInfo-tile mb-3">
                    <div className="subscriptionInfo-body ">
                      <ImgText
                        userBlock={"user-block-sm align-items-start"}
                        userIc={"primary-bg"}
                        userText={"d-lg-flex justify-content-between"}
                        userIcon={"icon-tablets white-text"}
                      >
                        <div className="user-text-inner me-2">
                          <div className="lg-text light-black-text b-700 user-textHeading">
                            Kidney function
                          </div>
                          <div className="singl-tag-tile warning-light-bg mt-2">
                            Recommended
                          </div>
                        </div>
                        <div className="text-nowrap mt-lg-0 mt-2 sm-text primary-text lg-text b-700">
                          £ 9.00
                        </div>
                      </ImgText>
                      <div className="fst-italic grey-text xs-text mt-3">
                        Recommended for you based on the focus of:
                        <div className="singl-tag-tile success-lightest-bg success-dark-text ms-3 fst-normal">
                          ENERGY
                        </div>
                      </div>
                    </div>
                    <div className="subscriptionInfo-footer text-end p-3 ">
                      <BtnMain
                        text="Add"
                        className="btn btn-primary LgfixWidth-btn "
                        type="button"
                      />
                    </div>
                  </div>
                  <div className="subscriptionInfo-tile mb-3">
                    <div className="subscriptionInfo-body ">
                      <ImgText
                        userBlock={"user-block-sm align-items-start"}
                        userIc={"primary-bg"}
                        userText={"d-lg-flex justify-content-between"}
                        userIcon={"icon-intravenous-therapy white-text"}
                      >
                        <div className="user-text-inner me-2">
                          <div className="lg-text light-black-text b-700 user-textHeading">
                            Kidney function
                          </div>
                          <div className="singl-tag-tile warning-light-bg mt-2">
                            Recommended
                          </div>
                        </div>
                        <div className="text-nowrap mt-lg-0 mt-2 sm-text primary-text lg-text b-700">
                          £ 0.45
                        </div>
                      </ImgText>
                      <div className="fst-italic grey-text xs-text mt-3">
                        Recommended for you based on the focus of:
                        <div className="singl-tag-tile success-lightest-bg success-dark-text ms-3 fst-normal">
                          ENERGY
                        </div>
                      </div>
                    </div>
                    <div className="subscriptionInfo-footer text-end p-3 ">
                      <BtnMain
                        text="Add"
                        className="btn btn-primary LgfixWidth-btn "
                        type="button"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="subscription-total ps-md-2">
                <div className="subscriptionInfo-heading xs-text mb-3 black-text b-700">
                  Your Package
                </div>
                <div className="subscriptionTotal-inner">
                  <ImgText
                    userBlock={"user-block-sm align-items-start"}
                    userIc={"primary-bg"}
                    userText={"d-lg-flex justify-content-between"}
                    userIcon={"icon-tablets white-text"}
                  >
                    <div className="user-text-inner">
                      <div className="lg-text light-black-text b-700 user-textHeading">
                        Thyroid Stimulating Hormone (TSH)
                      </div>
                      <div className="text-nowrap mt-lg-0 mt-1 sm-text black-text lg-text ">
                        ￡50
                      </div>
                    </div>
                    <span className="icon-times "></span>
                  </ImgText>
                  <div className="total-amount-wrap mb-2">
                    <div className=" d-flex justify-content-between align-items-center">
                      <span className="sm-text pe-3">Package total:</span>
                      <span className="sm-text b-700">£ 50</span>
                    </div>
                  </div>
                  <div className="total-amount-wrap mb-2">
                    <div className=" d-flex justify-content-between align-items-center">
                      <span className="sm-text pe-3">Processing fee:</span>
                      <span className="sm-text b-700">£ 15</span>
                    </div>
                  </div>
                  <div className="total-amount-wrap mb-3">
                    <div className=" d-flex justify-content-between align-items-center">
                      <span className="lg-text  b-700 pe-3">
                        Processing fee:
                      </span>
                      <span className="lg-text b-700">£ 15</span>
                    </div>
                  </div>
                  <BtnMain
                    text="Continue"
                    className="btn btn-primary LgfixWidth-btn "
                    type="button"
                  />
                </div>
              </div>
            </div>
          </Tab>
          <Tab
            eventKey="Frequency"
            title="Change Test Date &#38; Frequency"
          ></Tab>
          <Tab eventKey="CancelSubscription" title="Cancel Subscription"></Tab>
          <Tab
            eventKey="SubscriptionHistory"
            title="Subscription History"
          ></Tab>
        </Tabs>
      </div>
    </Layout>
  );
}
export default Subscription;
