import swal from "sweetalert";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Cookies from 'js-cookie';
/******************* 
@purpose : used for alert message 
@Parameter : {msg, type,autoHide}
@Author : INIC
******************/
export const alertDialogue = (msg, type = 'error', autoHide = true) => {
    swal(msg || 'Something Went Wrong', '', type);
    if (autoHide) {
        setTimeout(() => {
            swal.close();
        }, 3000);
    }
};

/******************* 
@purpose : Get Cookie
@Parameter : {name}
@Author : INIC
******************/
export function get_cookie(name) {
    if (name) {
        return Cookies.get(name);
    }
    return '';
}
/******************* 
@purpose : Set Cookie
@Parameter : {name, value}
@Author : INIC
******************/
export function set_cookie(name, value,expireDay) {
    if (name) {
        // expires after= 50000 days
        Cookies.set(name, value, { expires: expireDay });
    }
}
/*******************    
@purpose : Remove Cookie
@Parameter : {name}
@Author : INIC
******************/
export function remove_cookie(name) {
    if (name) {
        Cookies.remove(name);
    }
}
/*** 
@purpose : Validate String to decide whether it is valid object or not
@Parameter : {obj}
@Author : INIC
**/
export function isValidValue(obj) {
  let parseVal = null;
  try {
      JSON.parse(obj);
      parseVal = JSON.parse(obj);
  } catch (err) {
      parseVal = obj;
  }
  return parseVal;
}
/******************* 
@Purpose : Email Validation
@Parameter : email
@Author : INIC
******************/


export const validateEmail =
/^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
/******************* 
@Purpose : App ID and secret Validation
@Parameter : id
@Author : INIC
******************/
export const validateIDSecret = (id) => {
  const pattern = new RegExp(/^[a-zA-Z0-9_]{5,50}$/);
  return pattern.test(id);
};
/******************* 
@Purpose : Password Validation
@Parameter : pass
@Author : INIC
******************/
export const validatePassword =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[-!$@#%^&*()_+|~=`{}\[\]:";'<>?,.\/])[A-Za-z\d-!$@#%^&*()_+|~=`{}\[\]:";'<>?,.\/]{6,20}$/;
 

/******************* 
@Purpose : Username Validation
@Parameter : name
@Author : INIC
******************/
export const validateUsername = (name) => {
  var pattern = new RegExp(
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!_%-*?&#])[A-Za-z\d@$!_%-*?&#]{5,}$/
  );
  return pattern.test(name);
};
/******************* 
@Purpose : Port Validation
@Parameter : port
@Author : INIC
******************/
export const validatePort = (port) => {
  var pattern = new RegExp(/^([0-9]){3,4}$/);
  return pattern.test(port);
};
/******************* 
@Purpose : Hostname Validation
@Parameter : name
@Author : INIC
******************/
export const validateHostName = (name) => {
  var pattern = new RegExp(
    /^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$|^(([a-zA-Z0-9]|[a-zA-Z0-9][a-zA-Z0-9\-]*[a-zA-Z0-9])\.)+([A-Za-z]|[A-Za-z][A-Za-z0-9\-]*[A-Za-z0-9])$/
  );
  return pattern.test(name);
};
/******************* 
@Purpose : Mobile Number Validation
@Parameter : mobileNo
@Author : INIC
******************/
export const validateMobileNumber =  /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/
 

/******************* 
@Purpose : First and Last Name Validation
@Parameter : name
@Author : INIC
******************/
export const validateName = (name) => {
  const pattern = new RegExp(/^[a-z ,.'-]+$/i);
  return pattern.test(name);
};
/******************* 
@Purpose : Character Validation
@Parameter : e
@Author : INIC
******************/
export const allowChar = (e) => {
  const pattern = new RegExp(/^[a-zA-Z\s]{0,255}$/);
  return pattern.test(e);
};
/******************* 
@Purpose : URL Validation
@Parameter : url
@Author : INIC
******************/
export const validateURL = (url) => {
  const pattern = new RegExp(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return pattern.test(url);
};

/******************* 
@Purpose : Used for show modal notification
@Parameter : text, type, timer, position, buttons, className
@Author : INIC
******************/
export const showModalNotification = (
  text,
  type = "success",
  timer = 2500,
  position = "center",
  buttons = false
) => {
  swal({
    position: position,
    icon: type,
    text: text,
    buttons: buttons,
    timer: timer,
    className: "custom-toaster",
  });
};
/******************* 
@Purpose : Used for show message notification
@Parameter : text, type, autoClose, position
@Author : INIC
******************/
export const showMessageNotification = (
  text,
  type = "success",
  autoClose = 1500,
  position = "top-right",
) => {
  toast[type](text, {
    position: position,
    autoClose: autoClose,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};
/******************* 
@Purpose : Pincode Validation
@Parameter : msg
@Author : INIC
******************/
export const validatePincodeNumber = (mobileNo) => {
  var pattern = new RegExp(/^[0-9]{5}(?:-[0-9]{4})?$/);
  return pattern.test(mobileNo);
};
/******************* 
@Purpose : base 64 Image to file covert
@Parameter : {dataurl,filename}
@Author : INIC
******************/
export const dataURLtoFile =(dataurl, filename)=> {
 if(dataurl!=='')
 {
  var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), 
      n = bstr.length, 
      u8arr = new Uint8Array(n);
      
  while(n--){
      u8arr[n] = bstr.charCodeAt(n);
  }
  
  return new File([u8arr], filename, {type:mime});
}
};