import React from "react";
import { Form } from "react-bootstrap";
import BtnMain from "../../components/buttons/btn_main";
import Layout from "../../components/Layout/dashboard-layout/Layout";
import DrAccess from "./../../assets/images/temp-img/dr-access.svg";
import ImgText from "../../components/img-text/ImgText";

function DoctorAccess(_props) {
  /******************* 
@Purpose : Used for render DoctorAccess UI
@Parameter : {}
@Author : INIC
******************/

  return (
    <Layout>
      <div className="dashboard-inner-content dashboard-wrap pb-5">
        <div className="big-heading h3">Doctor access</div>
        <div className="idea-box align-items-center box-size-478 mb-4">
          <div className="idea-ic">
            <i className="icon-info-circle"></i>
          </div>
          <div className="idea-text">
            <div className="idea-subheading">
              Change the access your doctors have to your profile information
              and genotype data below
            </div>
          </div>
        </div>
        <div className="profileInfo-block">
          <div className="text-end b-700 lg-text primary-text mb-3 revoke-access">
            Revoke access to all
          </div>
          <div className="profileInfo-tile p-3 mb-4 ">
            <ImgText
              userBlock={"flex-auto flex-shrink-0 flex-grow-0"}
              userIc={"danger-bg"}
              userImgUrl={DrAccess}
            >
              <div className="sm-text light-black-text b-700 user-textHeading">
                Drug Response
              </div>
            </ImgText>
            <div className="profileInfo-middle-block  text-lg-center mt-3 mt-lg-0 ">
              Access granted since 15/01/2020
            </div>
            <div className="w-100 d-block d-lg-none"></div>
            <div className="profileInfo-action p-0  justify-content-start mt-3 mt-lg-0 ">
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=""
                  className="p-0"
                />
              </Form>
            </div>
          </div>
          <div className="profileInfo-tile p-3 mb-4 ">
            <ImgText
              userBlock={"flex-auto flex-shrink-0 flex-grow-0"}
              userIc={"danger-bg"}
              userImgUrl={DrAccess}
            >
              <div className="sm-text light-black-text b-700 user-textHeading">
                Drug Response
              </div>
            </ImgText>
            <div className="profileInfo-middle-block  text-lg-center mt-3 mt-lg-0 ">
              Access granted since 15/01/2020
            </div>
            <div className="w-100 d-block d-lg-none"></div>
            <div className="profileInfo-action p-0  justify-content-start mt-3 mt-lg-0 ">
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=""
                  className="p-0"
                />
              </Form>
            </div>
          </div>
          <div className="profileInfo-tile p-3 mb-4 ">
            <ImgText
              userBlock={"flex-auto flex-shrink-0 flex-grow-0"}
              userIc={"danger-bg"}
              userImgUrl={DrAccess}
            >
              <div className="sm-text light-black-text b-700 user-textHeading">
                Drug Response
              </div>
            </ImgText>
            <div className="profileInfo-middle-block  text-lg-center mt-3 mt-lg-0 ">
              Access granted since 15/01/2020
            </div>
            <div className="w-100 d-block d-lg-none"></div>
            <div className="profileInfo-action p-0  justify-content-start mt-3 mt-lg-0 ">
              <Form>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  label=""
                  className="p-0"
                />
              </Form>
            </div>
          </div>
        </div>
        <div className="">
          <BtnMain
            text="Save Changes"
            className="btn btn-primary  lg-btn"
            type="submit"
          >
            {" "}
          </BtnMain>
        </div>
      </div>
    </Layout>
  );
}
export default DoctorAccess;
