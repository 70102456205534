import React, { Fragment, useState } from "react";
import { Form, Breadcrumb } from "react-bootstrap";
import { useFormik } from "formik";
import UploadFile from "../../components/inputs/UploadFile";
import { profileSchema } from "../../utils/schema";
import Layout from "../../components/Layout/dashboard-layout/Layout";
import {  useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Loader from "../../components/Layout/loader/Loader";

import questionMark from "./../../assets/images/color-icon/question-mark.svg";
function FamilyMemberChild(_props) {
  const [previewBanner, SetpreviewBanner] = useState("");
  const isLoading = useSelector((state) => state?.ui?.loaders?.profile);
  const userInfo = useSelector((state) => state?.user?.userInfo);
  
  var state = "";
  if (userInfo.stateName != null) {
    state = userInfo.stateName;
  }
 
  const formik = useFormik({
    initialValues: {
      Title: "",
      FirstName: userInfo.firstName,
      LastName: userInfo.lastName,
      Email: userInfo.email,
      State: state,
      birthdate: "",
      image: "",
    },
    onSubmit(_values) {
           
    },
    validationSchema: profileSchema,
  });
  const onDrop = (files) => {
    if (files) {
      SetpreviewBanner(global.URL.createObjectURL(files[0]));
      formik?.setFieldValue("image", files[0]);
    }
  };
  /******************* 
@purpose : used for form Handle
@Parameter : {e} 
@Author : INIC
******************/
  const handleSubmit = (e) => {
    e.preventDefault();

    formik.submitForm();
  };
  /******************* 
@purpose : Render Profile Page UI 
@Author  : INIC
******************/

  return (
    <Layout>
      {isLoading && <Loader text="Loading" />}
      <div className="dashboard-inner-content">
        <Breadcrumb>
          <Breadcrumb.Item href="/assigned-tasks" className="sm-text ">
            Family members <span className="icon-arrow-right"></span>{" "}
          </Breadcrumb.Item>
          <Breadcrumb.Item active className="sm-text">
            Sarah Jack
          </Breadcrumb.Item>
        </Breadcrumb>
        <div className="profile-main-wrap">
          <Form onSubmit={handleSubmit} className="dashboard-form">
            <div className="current-report-wrap">
              <div className="currentReportProfile-block profile-wrap">
                <div className="user-block user-block-lg p-0">
                  <div className="userImg-block me-4 pe-2">
                    <UploadFile onDrop={onDrop} accept="image/*">
                      {previewBanner ? (
                        <div className="preview-wrap edit-banner-img">
                          <div className="preview-img">
                            <div className="preview-action">
                              <span
                                className="preview-ic icon-edit mb-0 me-1"
                                onClick={(e) => {
                                  SetpreviewBanner("");
                                  e.preventDefault();
                                }}
                              ></span>
                              <div className="b-700 sm-text">Edit</div>
                            </div>
                            <div
                              className="background-img"
                              style={{
                                backgroundImage: "url(" + previewBanner + ")",
                              }}
                            ></div>
                          </div>
                        </div>
                      ) : (
                        <Fragment>
                          <div className="profile-upload">
                            <span className="icon-image-upload"></span>
                            <div className="profileUpload-heading sm-text b-700">
                              Upload Avatar
                            </div>
                            <div className="profileUpload-subheading xs-text">
                              Photo must be below 5MB.
                            </div>
                          </div>
                        </Fragment>
                      )}
                      {formik.errors.image ? (
                        <div className="message-block">
                          {formik.errors.image}
                        </div>
                      ) : null}
                    </UploadFile>
                  </div>
                  <div className="user-text">
                    <div className="h3 mb-4 black-text b-700">
                      Sadie Jackson
                    </div>
                    <div className="tag-block blue-tag-block">
                      <div className="tag-tile">Female</div>
                      <div className="tag-tile">24 Years Old</div>
                      <div className="tag-tile">69 kg</div>
                      <div className="tag-tile">White</div>
                      <div className="tag-tile">United Kingdom</div>
                      <div className="tag-tile">Drinker</div>
                      <div className="tag-tile">Smoker</div>
                      <div className="tag-tile">Self-employed</div>
                      <div className="tag-tile">Alergies</div>
                      <div className="tag-tile">Asthma</div>
                    </div>
                  </div>
                </div>
                <div className="currentReportProfile-action d-flex">
                  <Link
                    to={"/prescriber/user-profile"}
                    className="btn gray-line-btn "
                  >
                    Edit profile{" "}
                  </Link>
                </div>
              </div>
            </div>
          </Form>
          <div className="profileInfo-wrap">
            <div className="grey-text xs-text mb-3 b-700 profileInfo-heading">
              Pharmacy Medicine Consultation{" "}
            </div>
            <div className="profileInfo-block black-text">
              <div className="profileInfo-tile">
                <div className="user-block">
                  <div className="userImg-block ">
                    <div className="userIc light-bg">
                      <img src={questionMark} alt={questionMark} />
                    </div>
                  </div>
                  <div className="user-text">
                    <div className="sm-text light-black-text b-700 user-textHeading">
                      General Questions
                    </div>
                    <div className="sm-text grey-text user-textSubHeading">
                      June 23, 2021 at 21:34
                    </div>
                  </div>
                </div>
                <div className="profileInfo-action d-flex">
                  <Link to={"/"} className="btn gray-line-btn ">
                    Update{" "}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default FamilyMemberChild;
