import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { login, googleLogin } from "../../store/Actions";
import { useFormik } from "formik";
import { loginSchema } from "../../utils/schema";
import Layout from "../../components/Layout/Layout";
import { Col, Container, Row, Form, FormGroup } from "react-bootstrap";
import { Link ,useHistory} from "react-router-dom";

import GoogleLogin from "react-google-login";
import AppleLogin from "react-apple-login";
import { APPLE_ID, APPLE_REDIRECT_URL, GOOGLE_ID } from "../../config/index";
import TextField from "../../components/inputs/textfield";
import BtnMain from "../../components/buttons/btn_main";
import Loader from "../../components/Layout/loader/Loader";
import ImgText from "../../components/img-text/ImgText";
function Login(props) {
  let history = useHistory();
  const isLoading = useSelector((state) => state?.ui?.loaders?.login);
  const [error, setError] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const { useAppleLoginPopup = true } = props;
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit(values) {
      const body = values;
      dispatch(login(body, "login")).then((res) => {
        if (res.success) {
          history.push("/dashboard");
          setError(false);
        } else setError(res.errorMessage);
      });
    },
    validationSchema: loginSchema,
  });
  /******************* 
    @Purpose : Used for Handle Form Submit 
    @Parameter : {e}
    @Author : INIC
  *******************/
  const handleSubmit = (e) => {
    e.preventDefault();
    formik.submitForm();
  };
  /******************* 
    @Purpose : Used for Handle Social Login 
    @Parameter : {e}
    @Author : INIC
  *******************/
  const handleSocialLogin = (type, _user) => {
    dispatch(googleLogin(type, "login")).then((res) => {
      if (!res.success) {
        setError(res.errorMessage);
      } else setError(res.errorMessage);
    });
  };
  /******************* 
@purpose : Render Login Page UI 
@Author  : INIC
******************/

  return (
    <Layout>
      {isLoading && <Loader text="Loading" />}
      <Container className="auth-main">
        <div className="auth-box">
          <div className="auth-heading">
            Welcome back! <br />
            It's great to see you again.
          </div>
          <Row className="authGoogleApple-wrap gx-3">
            <Col sm={6}>
              <GoogleLogin
                className="googlesignin-box"
                icon={false}
                clientId={GOOGLE_ID}
                buttonText="Sign up with Google"
                onSuccess={(user) => handleSocialLogin(user)}
                onFailure={(error) => handleSocialLogin(error)}
              />
            </Col>
            <Col sm={6} className="mt-sm-0 mt-2">
              <div className="signWith-apple">
                <button className="googlesignin-box">Sign up with Apple</button>
                <AppleLogin
                  clientId={String(APPLE_ID)}
                  redirectURI={APPLE_REDIRECT_URL}
                  callback={(user) => handleSocialLogin("apple", user)}
                  scope={"email name"}
                  responseMode={"form_post"}
                  designProp={{
                    height: 30,
                    width: 140,
                    color: "white",
                    border: false,
                    type: "sign-in",
                    border_radius: 15,
                    scale: 1,
                    locale: "en_US",
                  }}
                  usePopup={useAppleLoginPopup ? true : false}
                  onFailure={(error) => handleSocialLogin("apple", error)}
                ></AppleLogin>
              </div>
            </Col>
          </Row>
          <div className="or-text">or</div>
          <div className="form-wrap">
            <Form onSubmit={handleSubmit} noValidate>
              <FormGroup className="form-group input-ic-wrap">
                <TextField
                  type="email"
                  placeholder="Email address"
                  name="email"
                  formik={formik}
                  id="email"
                  showUntoucherError
                />
                <i className="icon-envelope-alt" aria-hidden="true"></i>
              </FormGroup>
              <FormGroup className="form-group input-ic-wrap">
                <TextField
                  type={passwordShown ? "text" : "password"}
                  placeholder="Password"
                  name="password"
                  formik={formik}
                  id="password"
                  showUntoucherError
                />
                <i
                  className={`${
                    passwordShown ? "icon-eye-slash" : "icon-eye"
                  } toggle-password`}
                  aria-hidden="true"
                  onClick={togglePasswordVisiblity}
                ></i>
              </FormGroup>
              <FormGroup className="form-group">
                <div className="condition-text text-end">
                  <Link to="/forgot-password">Forgot password?</Link>
                </div>
              </FormGroup>
              <FormGroup className="form-group mb-3 pb-3">
                <BtnMain
                  text="Sign in"
                  className={
                    !(formik.isValid && formik.dirty)
                      ? "btn btn-primary w-100 disabled-btn"
                      : "btn btn-primary w-100"
                  }
                  type="submit"
                  disabled={!(formik.isValid && formik.dirty)}
                />
              </FormGroup>
            </Form>
            {error && (
              <div className="login-block ">
                <ImgText
                  userBlock={"user-block-sm pe-0 align-items-start"}
                  userIc={"danger-bg"}
                  userIcon={"icon-times-circle white-text"}
                >
                  <div className="sm-text light-black-text user-textHeading">
                    Your sign in informamation is not correct.{" "}
                    <Link to={"/"} className="b-700">
                      Try again
                    </Link>{" "}
                    or{" "}
                    <Link to={"/forgot-password"} className="b-700">
                      reset your password.
                    </Link>{" "}
                  </div>
                </ImgText>
              </div>
            )}
          </div>
        </div>
      </Container>
    </Layout>
  );
}
export default Login;
