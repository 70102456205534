/******************* 
@Purpose : We can use following api calls and can change api name easily
@Author : INIC
******************/
export default {
    //Authentication
    LOGIN: 'Authentication/Login',
    REGISTER: "Registration/RegisterPatientUser",
    FORGOT: "Password/ForgotPassword",
    GOOGLEREGISTER: "Registration/RegisterPatient",
    RESETPASSWORD:"Password/PasswordRecoveryConfirm",
    REGISTERVERIFY:"PartnerRegistration/RegisterVerify",
    CHANGEPASSWORD:"Password/ChangePassword",
    ProfileData:"Registration/AddUpateUserProfile",
 
}

  