import React from "react";
import { Row, Col } from "react-bootstrap";
import BtnMain from "../../components/buttons/btn_main";
import Covid19_antibodies from "./../../assets/images/temp-img/Covid19_antibodies.jpeg";
import { Link } from "react-router-dom";
import Layout from "../../components/Layout/dashboard-layout/Layout";
import ImgText from "../../components/img-text/ImgText";
function DashBoard(props) {
  /******************* 
@Purpose : Used for render Dashboard UI
@Parameter : {}
@Author : INIC
******************/
  return (
    <Layout>
      <div className="dashboard-inner-content dashboard-wrap pb-5">
        <div className="big-heading h3">Hello Sarah! &#128075;</div>
        <div className="black-text xs-text b-700 profileInfo-heading mb-3">
          RECOMMENDATION{" "}
        </div>
        <div className="profileInfo-block black-text pb-3">
          <div className="profileInfo-tile active mb-3 ">
            <ImgText
              userIc={"warning-light-bg"}
              userIcon={"icon-question-circle black-text"}
            >
              <div className="sm-text light-black-text b-700 user-textHeading">
                ID not verified
              </div>
              <div className="sm-text black-text user-textSubHeading">
                You will recive email for verification please check your mail{" "}
              </div>
            </ImgText>
            <div className="profileInfo-action d-flex">
              <Link to={"/"} className="btn gray-line-btn me-3 text-nowrap">
                Do it later{" "}
              </Link>
              <Link to={"/"} className="btn btn-primary fixWidth-btn">
                Verfiy{" "}
              </Link>
            </div>
          </div>
        </div>
        <div className="black-text xs-text b-700 profileInfo-heading mb-3">
          Resent Covid Results{" "}
        </div>
        <div className="profileInfo-block black-text pb-3">
          <Row>
            <Col sm={6} className="pb-3">
              <div className="profileInfo-tile mb-3 p-3">
                <ImgText
                  userBlock={"pe-0"}
                  userIc={"info-dark-bg"}
                  userIcon={"icon-thermometer white-text"}
                >
                  <div className="sm-text light-black-text b-700 user-textHeading">
                    Day 8 Covid PCR Test{" "}
                  </div>
                  <div className="sm-text black-text user-textSubHeading">
                    Completed Dec 23, 2020
                  </div>
                </ImgText>
              </div>
            </Col>
            <Col sm={6} className="pb-3">
              <div className="profileInfo-tile mb-3 p-3">
                <ImgText
                  userBlock={"pe-0"}
                  userIc={"info-dark-bg"}
                  userIcon={"icon-thermometer white-text"}
                >
                  <div className="sm-text light-black-text b-700 user-textHeading">
                    Day 8 Covid PCR Test{" "}
                  </div>
                  <div className="sm-text black-text user-textSubHeading">
                    Completed Dec 23, 2020
                  </div>
                </ImgText>
              </div>
            </Col>
          </Row>
        </div>
        <div className="black-text xs-text b-700 profileInfo-heading mb-3">
          Recent Reports{" "}
        </div>
        <div className="profileInfo-block black-text pb-3">
          <div className="profileInfo-tile mb-3 p-3">
            <Row className="flex-grow-1">
              <Col xs={12} lg={6} className="">
                <div className="d-flex align-items-start mb-4 align-items-sm-center flex-column flex-sm-row">
                  <ImgText
                    userIc={"success-light-bg"}
                    userIcon={"icon-kayak white-text"}
                  >
                    <div className="sm-text light-black-text b-700 user-textHeading">
                      Fitness DNA Test
                    </div>
                    <div className="sm-text black-text user-textSubHeading">
                      Completed Jan 23, 2021
                    </div>
                  </ImgText>
                  <div className="text-end mb-3 mb-sm-0">
                    <div className="xs-text b-700 mb-2 text-nowrap">
                      Reports are ready! 🎉
                    </div>
                    <div className="xs-text ">100% test completion</div>
                  </div>
                </div>
                <div className="dashboardList">
                  <div className="dashboardList-heading lg-text b-700 mb-2">
                    Personalised fitness is the future.
                  </div>
                  <ul className="dashboardList-block sm-text">
                    <li className="dashboardList-tile ">
                      <span className="me-1">&#9679;</span>Unlock key insights
                      into how your body responds to different foods
                    </li>
                    <li className="dashboardList-tile">
                      <span className="me-1">&#9679;</span>Reimagine exercise by
                      learning how you are genetically wired
                    </li>
                    <li className="dashboardList-tile">
                      <span className="me-1">&#9679;</span>Access a 8 week diet
                      and exercise plan tailored to your DNA
                    </li>
                  </ul>
                  <Link
                    to={"/"}
                    className="d-flex  primary-text mb-3 mb-sm-0 b-700 align-items-center"
                  >
                    TimeLine{" "}
                    <span className="icon-angle-right sm-text ms-3"></span>
                  </Link>
                </div>
              </Col>
              <Col xs={12} lg={6}>
                <div className="reportStatus-block">
                  <div className="reportStatus-tile">
                    <div className="d-flex align-items-center">
                      <ImgText
                        userBlock={"user-block-sm"}
                        userIc={"warning-bg"}
                        userIcon={"icon-food white-text"}
                      >
                        <div className="sm-text light-black-text b-700 user-textHeading">
                          Diet & Nutrition
                        </div>
                      </ImgText>
                      <div className="reportStatus-action">
                        <BtnMain
                          className="reportStatus-btn "
                          type="button"
                          iconLeft={
                            <span className="icon-lock-open-alt"></span>
                          }
                          onClick={props.SidebarHandle}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="reportStatus-tile">
                    <div className="d-flex align-items-center">
                      <ImgText
                        userBlock={"user-block-sm"}
                        userIc={"warning-bg"}
                        userIcon={"icon-weight white-text"}
                      >
                        <div className="sm-text light-black-text b-700 user-textHeading">
                          {" "}
                          Body & Weight Management
                        </div>
                      </ImgText>
                      <div className="reportStatus-action">
                        <BtnMain
                          className="reportStatus-btn "
                          type="button"
                          iconLeft={
                            <span className="icon-lock-open-alt"></span>
                          }
                          onClick={props.SidebarHandle}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="reportStatus-tile">
                    <div className="d-flex align-items-center">
                      <ImgText
                        userBlock={"user-block-sm"}
                        userIc={"warning-bg"}
                        userIcon={"icon-dumbbell white-text"}
                      >
                        <div className="sm-text light-black-text b-700 user-textHeading">
                          Exercise Response
                        </div>
                      </ImgText>
                      <div className="reportStatus-action">
                        <BtnMain
                          className="reportStatus-btn "
                          type="button"
                          iconLeft={
                            <span className="icon-lock-open-alt"></span>
                          }
                          onClick={props.SidebarHandle}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
          <div className="profileInfo-tile mb-3 p-3">
            <Row className="flex-grow-1">
              <Col xs={12} lg={6} className="">
                <div className="d-flex align-items-start mb-4 align-items-sm-center flex-column flex-sm-row">
                  <ImgText
                    userIc={"success-light-bg"}
                    userIcon={"icon-kayak white-text"}
                  >
                    <div className="sm-text light-black-text b-700 user-textHeading">
                      Heart DNA Test
                    </div>
                    <div className="sm-text black-text user-textSubHeading">
                      Completed Jan 23, 2021
                    </div>
                  </ImgText>
                  <div className="text-end mb-3 mb-sm-0">
                    <div className="xs-text b-700 mb-2 text-nowrap">
                      Reports are ready! 🎉
                    </div>
                    <div className="xs-text ">100% test completion</div>
                  </div>
                </div>
                <div className="dashboardList">
                  <div className="dashboardList-heading lg-text b-700 mb-2">
                    Supercharge your heart health with this package
                  </div>
                  <ul className="dashboardList-block sm-text">
                    <li className="dashboardList-tile ">
                      <span className="me-1">&#9679;</span>Learn about possible
                      risks and how to prevent them
                    </li>
                    <li className="dashboardList-tile">
                      <span className="me-1">&#9679;</span>Get a full medical
                      assessment and review from a doctor{" "}
                    </li>
                    <li className="dashboardList-tile">
                      <span className="me-1">&#9679;</span>Benefit from heart
                      medications chosen with your DNA in mind
                    </li>
                  </ul>
                  <Link
                    to={"/"}
                    className="d-flex  primary-text mb-3 mb-sm-0 b-700 align-items-center"
                  >
                    TimeLine{" "}
                    <span className="icon-angle-right sm-text ms-3"></span>
                  </Link>
                </div>
              </Col>
              <Col xs={12} lg={6}>
                <div className="reportStatus-block">
                  <div className="reportStatus-tile">
                    <div className="d-flex align-items-center">
                      <ImgText
                        userBlock={"user-block-sm"}
                        userIc={"warning-bg"}
                        userIcon={"icon-pill white-text"}
                      >
                        <div className="sm-text light-black-text b-700 user-textHeading">
                          Drug Response
                        </div>
                      </ImgText>
                      <div className="reportStatus-action">
                        <BtnMain
                          className="reportStatus-btn "
                          type="button"
                          iconLeft={
                            <span className="icon-lock-open-alt"></span>
                          }
                          onClick={props.SidebarHandle}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="reportStatus-tile">
                    <div className="d-flex align-items-center">
                      <ImgText
                        userBlock={"user-block-sm"}
                        userIc={"warning-bg"}
                        userIcon={"icon-monitor-heart-rate white-text"}
                      >
                        <div className="sm-text light-black-text b-700 user-textHeading">
                          Cardiac Screening
                        </div>
                      </ImgText>
                      <div className="reportStatus-action">
                        <BtnMain
                          className="reportStatus-btn "
                          type="button"
                          iconLeft={
                            <span className="icon-lock-open-alt"></span>
                          }
                          onClick={props.SidebarHandle}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="reportStatus-tile">
                    <div className="d-flex align-items-center">
                      <ImgText
                        userBlock={"user-block-sm"}
                        userIc={"warning-bg"}
                        userIcon={"icon-dna white-text"}
                      >
                        <div className="sm-text light-black-text b-700 user-textHeading">
                          Lipids Metabolism
                        </div>
                      </ImgText>
                      <div className="reportStatus-action">
                        <BtnMain
                          className="reportStatus-btn "
                          type="button"
                          iconLeft={
                            <span className="icon-lock-open-alt"></span>
                          }
                          onClick={props.SidebarHandle}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="reportStatus-tile">
                    <div className="d-flex align-items-center">
                      <ImgText
                        userBlock={"user-block-sm"}
                        userIc={"warning-bg"}
                        userIcon={"icon-hospital-building white-text"}
                      >
                        <div className="sm-text light-black-text b-700 user-textHeading">
                          {" "}
                          Thrombophilia
                        </div>
                      </ImgText>
                      <div className="reportStatus-action">
                        <BtnMain
                          className="reportStatus-btn "
                          type="button"
                          iconLeft={
                            <span className="icon-lock-open-alt"></span>
                          }
                          onClick={props.SidebarHandle}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="black-text xs-text b-700 profileInfo-heading mb-3">
          Recent Goal{" "}
        </div>
        <Row>
          <Col md={6}>
            <div className="profileInfo-block black-text pb-3">
              <div className="profileInfo-tile  mb-3 ">
                <ImgText
                  userBlock={"align-items-start"}
                  userIc={"danger-dark-bg"}
                  userIcon={"icon-stacked white-text"}
                >
                  <div className="sm-text black-text b-700 user-textHeading">
                    Lower LDL cholesterol
                  </div>
                  <div className="sm-text grey-text user-textSubHeading mb-3">
                    Created by{" "}
                    <Link to={"/"} className="underline-anchor">
                      Dr. Phil Jackson
                    </Link>
                  </div>
                  <div className="sm-text grey-text user-textSubHeading mb-3">
                    From <span className="b-700">240</span> to{" "}
                    <span className="b-700">180</span> by{" "}
                    <span className="b-700">Jan 03, 2021.</span>
                  </div>
                  <BtnMain
                    className="btn gray-line-btn  btn-sm btn-radius"
                    type="button"
                    text="Biomarker"
                    onClick={props.SidebarHandle}
                  />
                </ImgText>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="profileInfo-block black-text pb-3">
              <div className="profileInfo-tile  mb-3 ">
                <ImgText
                  userBlock={"align-items-start"}
                  userIc={"orange-bg"}
                  userIcon={"icon-weight white-text"}
                >
                  <div className="sm-text black-text b-700 user-textHeading">
                    Lower LDL cholesterol
                  </div>
                  <div className="sm-text grey-text user-textSubHeading mb-3">
                    Created by{" "}
                    <Link to={"/"} className="underline-anchor">
                      Dr. Phil Jackson
                    </Link>
                  </div>
                  <div className="sm-text grey-text user-textSubHeading mb-3">
                    From <span className="b-700">240</span> to{" "}
                    <span className="b-700">180</span> by{" "}
                    <span className="b-700">Jan 03, 2021.</span>
                  </div>
                  <BtnMain
                    className="btn gray-line-btn  btn-sm btn-radius"
                    type="button"
                    text="Biomarker"
                    onClick={props.SidebarHandle}
                  />
                </ImgText>
              </div>
            </div>
          </Col>
        </Row>
        <div className="order-wrap">
          <div className="black-text xs-text b-700 order-heading mb-3">
            Recent Orders{" "}
          </div>
          <Row className="">
            <Col className="" md={4}>
              <div className="order-block">
                <div className="order-img-block ">
                  <div
                    className="order-img "
                    style={{
                      backgroundImage: "url(" + Covid19_antibodies + ")",
                    }}
                  ></div>
                </div>
                <div className="order-text ">
                  <div className="order-heading lg-text b-700 mb-2">
                    Vitamin D3 50000 IU
                  </div>
                  <div className="order-subheading sm-text grey-text mb-2">
                    Azithromycin is used to treat many different types of
                    infections caused by bacteria, such as respiratory
                    infections, skin infections, ear infections, eye infections
                    diseases.
                  </div>
                </div>
                <div className="order-footer">
                  <div className="order-price">
                    <span className="new-price me-2 lg-text b-700">￡120</span>
                    <span className="old-price xs-text grey-text">￡149 </span>
                  </div>
                  <div className="order-action">
                    <div className="order-status b-700 complete-status">
                      Completed
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="" md={4}>
              <div className="order-block">
                <div className="order-img-block ">
                  <div
                    className="order-img "
                    style={{
                      backgroundImage: "url(" + Covid19_antibodies + ")",
                    }}
                  ></div>
                </div>
                <div className="order-text ">
                  <div className="order-heading lg-text b-700 mb-2">
                    Vitamin D3 50000 IU
                  </div>
                  <div className="order-subheading sm-text grey-text mb-2">
                    Azithromycin is used to treat many different types of
                    infections caused by bacteria, such as respiratory
                    infections, skin infections, ear infections, eye infections
                    diseases.
                  </div>
                </div>
                <div className="order-footer">
                  <div className="order-price">
                    <span className="new-price me-2 lg-text b-700">￡120</span>
                    <span className="old-price xs-text grey-text">￡149 </span>
                  </div>
                  <div className="order-action">
                    <div className="order-status b-700 pending-status">
                      In progress
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col className="" md={4}>
              <div className="order-block">
                <div className="order-img-block ">
                  <div
                    className="order-img "
                    style={{
                      backgroundImage: "url(" + Covid19_antibodies + ")",
                    }}
                  ></div>
                </div>
                <div className="order-text ">
                  <div className="order-heading lg-text b-700 mb-2">
                    Vitamin D3 50000 IU
                  </div>
                  <div className="order-subheading sm-text grey-text mb-2">
                    Azithromycin is used to treat many different types of
                    infections caused by bacteria, such as respiratory
                    infections, skin infections, ear infections, eye infections
                    diseases.
                  </div>
                </div>
                <div className="order-footer">
                  <div className="order-price">
                    <span className="new-price me-2 lg-text b-700">￡120</span>
                    <span className="old-price xs-text grey-text">￡149 </span>
                  </div>
                  <div className="order-action">
                    <div className="order-status b-700 danger-status">
                      Verify ID First
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Layout>
  );
}
export default DashBoard;
