import React, { useState } from "react";
import Header from "./Header";
import SideBar from "./SideBar";
function Layout(props) {
  const [sidebar, Setsidebar] = useState(false);

  const SidebarHandle = () => {
    Setsidebar(!sidebar);
  };
/******************* 
@Purpose : Used for render Layout UI
@Parameter : {}
@Author : INIC
******************/
  return (
    <div className="main-layout">
      <div
        className={
          sidebar ? "blacklayer-sidebar show" : "blacklayer-sidebar hide"
        }
        onClick={SidebarHandle}
      ></div>
      <Header SidebarHandle={SidebarHandle} />
      <div className="dashboard-layout-wrap">
        <SideBar sidebar={sidebar} />
        <div className="dashboard-content">{props.children}</div>
      </div>
    </div>
  );
}
export default Layout;
