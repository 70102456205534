import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../store/Actions";
import { useFormik } from "formik";
import { forgotPasswordSchema } from "../../utils/schema";
import Layout from "../../components/Layout/Layout";
import { Link } from "react-router-dom";
import { Form, FormGroup, Container } from "react-bootstrap";
import "react-datetime/css/react-datetime.css";
import TextField from "../../components/inputs/textfield";
import BtnMain from "../../components/buttons/btn_main";
import Loader from "../../components/Layout/loader/Loader";
import ImgText from "../../components/img-text/ImgText";

function ForgotPassword(_props) {
  const isLoading = useSelector((state) => state?.ui?.loaders?.forgot);
  const [error, setError] = useState(false);
  const [successMsg, setSuccess] = useState(false);

  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit(values, { resetForm }) {
      const body = values;
      dispatch(forgotPassword(body, "forgot")).then((res) => {
        if (res.success) {
          setError(false);
          setSuccess(true);
          resetForm({ values: "" });
        } else {
          setError(true);
          setSuccess(false);
        }
      });
    },
    validationSchema: forgotPasswordSchema,
  });

  /******************* 
    @Purpose : Used for Handle Form Submit 
    @Parameter : {e}
    @Author : INIC
  *******************/
  const handleSubmit = (e) => {
    e.preventDefault();
    formik.submitForm();
  };

  /******************* 
@purpose : Render Forgot Password Page UI 
@Author  : INIC
******************/

  return (
    <Layout>
      {isLoading && <Loader text="Loading" />}
      <Container className="auth-main">
        <div className="auth-box">
          <div className="auth-heading">Forgot Your Password</div>
          <div className="auth-subheading sm-text">
            No problem. Please enter your email address and we'll send you a
            recovery link to reset your password.
          </div>
          <div className="form-wrap">
            {successMsg && (
              <div className="login-block mb-3 pb-3">
                <ImgText
                  userBlock={"user-block-sm pe-0 align-items-start"}
                  userIc={"success-bg"}
                  userIcon={"icon-check-circle white-text"}
                >
                  <div className="sm-text light-black-text user-textHeading">
                    If the address you just typed matches a Rightangled account,
                    we will send an email with instructions to reset your
                    password.
                    <div>
                      Didn’t get the email?{" "}
                      <Link to={"/"} className="b-700">
                        Try again below.
                      </Link>
                    </div>
                  </div>
                </ImgText>
              </div>
            )}
            <Form onSubmit={handleSubmit} noValidate>
              <FormGroup className="form-group input-ic-wrap">
                <TextField
                  type="email"
                  placeholder="Email address"
                  name="email"
                  formik={formik}
                  id="email"
                  showUntoucherError
                />
                <i className="icon-envelope-alt" aria-hidden="true"></i>
              </FormGroup>
              <FormGroup className="form-group text-center mb-0">
                <BtnMain
                  text="Send Email "
                  className={
                    !(formik.isValid && formik.dirty)
                      ? "b-400 btn btn-primary w-100 password-btn"
                      : "password-btn btn w-100 btn-primary"
                  }
                  type="submit"
                  disabled={!(formik.isValid && formik.dirty)}
                />
                <Link to="/login" className="back-login sm-text">
                  Back to Sign in
                </Link>
              </FormGroup>
            </Form>
            {error && (
              <div className="login-block ">
               
                <ImgText
                  userBlock={"user-block-sm pe-0 align-items-start"}
                  userIc={"danger-bg"}
                  userIcon={"icon-times-circle white-text"}
                >
                  <div className="sm-text light-black-text user-textHeading">
                    Your informamation is not correct.{" "}
                  </div>
                </ImgText>
              </div>
            )}
          </div>
        </div>
      </Container>
    </Layout>
  );
}
export default ForgotPassword;
