import React from "react";
import BtnMain from "../../../../../components/buttons/btn_main";

function StepsHeader(props) {
  /******************* 
@Purpose : Used for render StepsHeader UI
@Parameter : {}
@Author : INIC
******************/
  return (
    <div className="step-header">
      <div className="stepBtn-group">
        <div
          className="stepBack-btn sm-text black-text"
          onClick={() =>
            props.stepsProps.goToStep(props.stepsProps.currentStep - 1)
          }
        >
          Back
        </div>
        <BtnMain
          text="Next"
          className="btn btn-primary fixWidth-btn"
          onClick={() =>
            props.stepsProps.goToStep(props.stepsProps.currentStep + 1)
          }
          type="submit"
        />
       
      </div>
      <div className="progress-baar">
        <div
          className="progress-baar-value"
          style={{
            width:
              (props.stepsProps.currentStep / props.stepsProps.totalSteps) *
                100 +
              "%",
          }}
        ></div>
      </div>
    </div>
  );
}
export default StepsHeader;
