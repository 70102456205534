import React from "react";
import { Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import Layout from "../../../components/Layout/dashboard-layout/Layout";
import ImgText from "../../../components/img-text/ImgText";
function RegisterKitList(_props) {


  /******************* 
@Purpose : Used for render RegisterKitList UI
@Parameter : {}
@Author : INIC
******************/

  return (
    <Layout>
      <div className="dashboard-inner-content dashboard-wrap pb-5">
        <div className="big-heading h3">Register a Kit</div>
        <Row className="profileInfo-block">
          <Col xs={12} lg={6} className="">
            <div className="profileInfo-tile p-3 mb-4">
              <ImgText
                userImg={"rounded-border"}
                userIc={"success-light-bg"}
                userIcon={"icon-heart white-text"}
              >
                <div className="sm-text black-text b-700 user-textHeading">
                  Heart DNA Test
                </div>
                <div className="sm-text black-text user-textSubHeading">
                  Completed Dec 04, 2020
                </div>
              </ImgText>
              <div className="profileInfo-action d-flex align-items-center">
                <Link to={"/"} className="btn gray-line-btn">
                  View
                </Link>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={6} className="">
            <div className="profileInfo-tile p-3 mb-4">
              <ImgText
                userImg={"rounded-border"}
                userIc={"success-light-bg"}
                userIcon={"icon-kayak white-text"}
              >
                <div className="sm-text black-text b-700 user-textHeading">
                  Fitness DNA Test
                </div>
                <div className="sm-text black-text user-textSubHeading">
                  Completed Jan 23, 2021
                </div>
              </ImgText>
              <div className="profileInfo-action d-flex align-items-center">
                <Link to={"/"} className="btn gray-line-btn">
                  View
                </Link>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={6} className="">
            <div className="profileInfo-tile p-3 mb-4">
              <ImgText
                userImg={"rounded-border"}
                userIc={"success-light-bg"}
                userIcon={"icon-heart white-text"}
              >
                <div className="sm-text black-text b-700 user-textHeading">
                  Wellness Pro
                </div>
                <div className="sm-text black-text user-textSubHeading">
                  Completed Dec 04, 2020
                </div>
              </ImgText>
              <div className="profileInfo-action d-flex align-items-center">
                <Link to={"/"} className="btn gray-line-btn">
                  View
                </Link>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={6} className="">
            <div className="profileInfo-tile p-3 mb-4">
              <ImgText
                userImg={"rounded-border"}
                userIc={"success-light-bg"}
                userIcon={"icon-kayak white-text"}
              >
                <div className="sm-text black-text b-700 user-textHeading">
                  Food & Diet
                </div>
                <div className="sm-text black-text user-textSubHeading">
                  Completed Jan 23, 2021
                </div>
              </ImgText>
              <div className="profileInfo-action d-flex align-items-center">
                <Link to={"/"} className="btn gray-line-btn">
                  View
                </Link>
              </div>
            </div>
          </Col>
          <Col xs={12} lg={6} className="">
            <div className="add-more">
              <div className="add-more-img me-3">
                <span className="icon-plus"></span>
              </div>
              <div className="add-more-text sm-text primary-text">
                Add new kit
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Layout>
  );
}
export default RegisterKitList;
