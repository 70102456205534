import React from "react";

import { useFormik } from "formik";
import StepsHeader from "./steps-header/StepsHeader";
import Select from "react-select";
import { FormGroup } from "react-bootstrap";

function Step1(props) {
  const formik = useFormik({
    initialValues: {
      selectProfession: "",
      registration: "",
    },
    onSubmit(_values) {
    },
  });
  const titleOptions = [
    { value: "", label: "Select Title" },
    { value: "Prof", label: "Prof" },
    { value: "Dr", label: "Dr." },
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Ms", label: "Ms" },
    { value: "Miss", label: "Miss" },
  ];
  /******************* 
@Purpose : Used for render Step1 UI
@Parameter : {}
@Author : INIC
******************/
  return (
    <div className="step-inner-wrap">
      <StepsHeader stepsProps={props} />
      <div className="step-body">
        <div className="step-heading h6">
          Which type of test are you registering your kit for?
        </div>
        <div className="step-form">
          <FormGroup className="form-group">
            <Select
              classNamePrefix="custom-select"
              placeholder="Select answer"
              type="text"
              id="title"
              name="title"
              onChange={(e) => {
                formik?.setFieldValue("title", e.value);
              }}
              options={titleOptions}
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
}
export default Step1;
