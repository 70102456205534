import React from "react";
import { Row, Col} from "react-bootstrap";
import Layout from "../../components/Layout/dashboard-layout/Layout";
import medicine from "./../../assets/images/temp-img/medicine.png";
import logoBlueGg from "./../../assets/images/logo-blue-bg.svg";
import ImgText from "../../components/img-text/ImgText";
function OrdersKits(_props) {


  /******************* 
@Purpose : Used for render OrdersKits UI
@Parameter : {}
@Author : INIC
******************/

  return (
    <Layout>
      <div className="dashboard-inner-content dashboard-wrap pb-5">
        <div className="big-heading h3">Orders and Kits </div>
        <div className="black-text xs-text b-700 mb-3">Registered kits</div>
        <div className="idea-box align-items-center mb-4">
          <div className="idea-ic">
            <i className="icon-info-circle"></i>
          </div>
          <div className="idea-text">
            <div className="idea-subheading">
              These are the kits that you have blah blah blah.
            </div>
          </div>
        </div>
        <Row className="profileInfo-block orderKits-wrap">
          <Col md={6} xs={12} className="">
            <div className="profileInfo-tile p-3 mb-4 ">
              <ImgText
                userBlock={"user-block-sm align-items-start"}
                userIc={"danger-bg"}
                userImg={"rounded-border"}
                userImgUrl={logoBlueGg}
              >
                <div className="sm-text light-black-text b-700 user-textHeading latter-spacing  ">
                  HD1-SOD-RHITY74
                </div>
                <div className="sm-text black-text user-textSubHeading ">
                  Status: Sample decoded
                </div>
                <div className="sm-text black-text user-textSubHeading ">
                  Date registered: Sep 17, 2021 at 11:38am
                </div>
              </ImgText>
            </div>
          </Col>
          <Col md={6} xs={12} className="">
            <div className="profileInfo-tile p-3 mb-4 ">
              <ImgText
                userBlock={"user-block-sm align-items-start"}
                userIc={"danger-bg"}
                userImg={"rounded-border"}
                userImgUrl={logoBlueGg}
              >
                <div className="sm-text light-black-text b-700 user-textHeading latter-spacing  ">
                  HD1-SOD-RHITY74
                </div>
                <div className="sm-text black-text user-textSubHeading ">
                  Status: Sample decoded
                </div>
                <div className="sm-text black-text user-textSubHeading ">
                  Date registered: Sep 17, 2021 at 11:38am
                </div>
              </ImgText>
            </div>
          </Col>
          <Col md={6} xs={12} className="">
            <div className="profileInfo-tile p-3 mb-4 ">
              <ImgText
                userBlock={"user-block-sm align-items-start"}
                userIc={"danger-bg"}
                userImg={"rounded-border"}
                userImgUrl={logoBlueGg}
              >
                <div className="sm-text light-black-text b-700 user-textHeading latter-spacing  ">
                  HD1-SOD-RHITY74
                </div>
                <div className="sm-text black-text user-textSubHeading ">
                  Status: Sample decoded
                </div>
                <div className="sm-text black-text user-textSubHeading ">
                  Date registered: Sep 17, 2021 at 11:38am
                </div>
              </ImgText>
            </div>
          </Col>
          <Col md={6} xs={12} className="">
            <div className="profileInfo-tile p-3 mb-4 ">
              <ImgText
                userBlock={"user-block-sm align-items-start"}
                userIc={"danger-bg"}
                userImg={"rounded-border"}
                userImgUrl={logoBlueGg}
              >
                <div className="sm-text light-black-text b-700 user-textHeading latter-spacing  ">
                  HD1-SOD-RHITY74
                </div>
                <div className="sm-text black-text user-textSubHeading ">
                  Status: Sample decoded
                </div>
                <div className="sm-text black-text user-textSubHeading ">
                  Date registered: Sep 17, 2021 at 11:38am
                </div>
              </ImgText>
            </div>
          </Col>

          <Col xs={12} lg={6} className="">
            <div className="add-more">
              <div className="add-more-img me-3">
                <span className="icon-plus"></span>
              </div>
              <div className="add-more-text sm-text primary-text">
                Add New kit
              </div>
            </div>
          </Col>
        </Row>
        <div className="medicine-wrap">
          <div className="black-text xs-text b-700 medicine-heading mb-3">
            Previous purchases{" "}
          </div>
          <div className="medicine-block">
            <Row>
              <Col md={6}>
                <div className="medicine-tile">
                  <ImgText
                    userBlock={"user-block-md"}
                    userIc={"danger-bg"}
                    userImg={"rounded-border"}
                    userImgUrl={medicine}
                  >
                    <div className="sm-text light-black-text b-700 user-textHeading mb-2">
                      Azithromycin 250 mg
                    </div>
                    <div className="sm-text grey-text user-textSubHeading mb-2">
                      Our COVID-19 Antibody test will tell you...
                    </div>
                    <div className="d-md-flex justify-content-between">
                      <div className="sm-text black-text">Dec 01, 2021</div>
                      <div className="sm-text black-text">Quantity: 2</div>
                    </div>
                  </ImgText>
                </div>
              </Col>
              <Col md={6}>
                <div className="medicine-tile">
                  <ImgText
                    userBlock={"user-block-md"}
                    userIc={"danger-bg"}
                    userImg={"rounded-border"}
                    userImgUrl={medicine}
                  >
                    <div className="sm-text light-black-text b-700 user-textHeading mb-2">
                      Azithromycin 250 mg
                    </div>
                    <div className="sm-text grey-text user-textSubHeading mb-2">
                      Our COVID-19 Antibody test will tell you...
                    </div>
                    <div className="d-md-flex justify-content-between">
                      <div className="sm-text black-text">Dec 01, 2021</div>
                      <div className="sm-text black-text">Quantity: 2</div>
                    </div>
                  </ImgText>
                </div>
              </Col>
              <Col md={6}>
                <div className="medicine-tile">
                  <ImgText
                    userBlock={"user-block-md"}
                    userIc={"danger-bg"}
                    userImg={"rounded-border"}
                    userImgUrl={medicine}
                  >
                    <div className="sm-text light-black-text b-700 user-textHeading mb-2">
                      Azithromycin 250 mg
                    </div>
                    <div className="sm-text grey-text user-textSubHeading mb-2">
                      Our COVID-19 Antibody test will tell you...
                    </div>
                    <div className="d-md-flex justify-content-between">
                      <div className="sm-text black-text">Dec 01, 2021</div>
                      <div className="sm-text black-text">Quantity: 2</div>
                    </div>
                  </ImgText>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </Layout>
  );
}
export default OrdersKits;
