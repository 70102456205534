import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import welcomeImg from "../assets/images/welcome-bg.svg";
import Layout from "../components/Layout/Layout";

function Welcome(_props) {
  /******************* 
@purpose : Render Welcome Page UI 
@Author  : INIC
******************/
  return (
    <Layout>
      <Container>
        <div className="welcome-wrap">
          <div className="welcome-text-block">
            <h1 className="welcome-text-heading h2">
              Persionlised health care in your pocket.
            </h1>
            <div className="welcome-btn-wrap">
              <Link to="/register" className="btn btn-primary">
                Sign up
              </Link>
              <Link to="/login" className="btn btn-outline-primary">
                Sign in
              </Link>
            </div>
            <div className="welcome-or sm-text b-700">Or</div>
            <Link to="/kit-ragister">Register a Kit </Link>
          </div>
          <div className="welcome-img-block">
            <img src={welcomeImg} alt={welcomeImg} />
          </div>
        </div>
      </Container>
    </Layout>
  );
}

export default Welcome;
