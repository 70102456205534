import React from "react";
function ImgText(props) {
  /******************* 
@Purpose : Used for render ImgText Component
@Parameter : {}
@Author : INIC
******************/
  return (
    <div className={`user-block ${props.userBlock ? props.userBlock : ""}`}>
      <div
        className={`userImg-block ${
          props.userImgBlock ? props.userImgBlock : ""
        }`}
      >
        {props.userIcon ? (
          <div className={`userIc ${props.userIc ? props.userIc : ""}`}>
            <span className={`${props.userIcon ? props.userIcon : ""}`}></span>
          </div>
        ) : (
          <div
            className={`user-img ${props.userImg ? props.userImg : ""}`}
            style={{ backgroundImage: "url(" + props.userImgUrl + ")" }}
          ></div>
        )}
      </div>
      <div className={`user-text ${props.userText ? props.userText : ""} `}>
        {props.children}
      </div>
    </div>
  );
}

export default ImgText;
