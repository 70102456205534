import * as types from '../Actions/Types';
import { get_cookie, isValidValue } from '../../utils'; //Utility functions
/******************* 
@purpose : Intital user reducer data
@Author : INIC
******************/
const initialState = {
    token: get_cookie && isValidValue(get_cookie('token')),
    refresh_token:get_cookie && get_cookie('refresh_token'),
    userInfo: {},
    userInfoUpdated: {},
       
};
/******************* 
@purpose : ui reducer
@Parameter : {user_ini_data, action}
@Author : INIC
******************/
const user = (user_ini_data = initialState, action = {}) => {
    switch (action.type) {
        // Set user authorized token
        case types.SET_TOKEN:
            return Object.assign({}, user_ini_data, {
                token: action.tokens.access,
                refresh_token: action.tokens.refresh,
            });
        // Remove user authorized token
        case types.LOGOUT_USER:
            return Object.assign({}, user_ini_data, {
                token: false,
                refresh_token: false,
            });
        // Set login user information
        case types.SET_USER_INFO:
            return Object.assign({}, user_ini_data, {
                userInfo: { ...user_ini_data.data, ...action.data },
            });
           // Set Updated user information
            case types.SET_USER_INFO_UPDATED:
                return Object.assign({}, user_ini_data, {
                    userInfoUpdated: { ...user_ini_data.data, ...action.data },
                });
             
    
       
        default:
            return user_ini_data;
    }
};

export default user;
