import React from "react";

import { useFormik } from "formik";
import StepsHeader from "./steps-header/StepsHeader";
import Select from "react-select";
import { FormGroup } from "react-bootstrap";
import DateTime from "react-datetime";
import moment from "moment";
function Step4(props) {
  /******************* 
@purpose : used for form Handle
@Parameter : {e} 
@Author : INIC
******************/

  const formik = useFormik({
    initialValues: {
      selectProfession: "",
      registration: "",
    },
    onSubmit(_values) {
     
    },
  });
  const titleOptions = [
    { value: "", label: "Select Title" },
    { value: "Prof", label: "Prof" },
    { value: "Dr", label: "Dr." },
    { value: "Mr", label: "Mr" },
    { value: "Mrs", label: "Mrs" },
    { value: "Ms", label: "Ms" },
    { value: "Miss", label: "Miss" },
  ];
  /******************* 
@Purpose : Used for render Step4 UI
@Parameter : {}
@Author : INIC
******************/
  return (
    <div className="step-inner-wrap">
      <StepsHeader stepsProps={props} />
      <div className="step-body">
        <div className="step-heading h6 mb-4 pb-3">When is your birthday?</div>
        <div className="step-form">
          <FormGroup
            className={`form-group input-ic-wrap  ${
              formik.values.BirthDate ? "date-filled" : "date-empty"
            }`}
          >
            <DateTime
              name="BirthDate"
              placeholder="Date of birth"
              value={formik.values.BirthDate}
              locale="en-US"
              dateFormat="DD/MM/YYYY"
              closeOnSelect
              timeFormat={false}
              onChange={(e) => {
                var dboData = moment(e).format("YYYY-MM-DD");
                formik?.setFieldValue("BirthDate", dboData);
              }}
              inputProps={{
                placeholder: "Date of birth",
              }}
            />
            <i className="icon-calender" aria-hidden="true"></i>
            {formik.errors.BirthDate ? (
              <div className="message-block">{formik.errors.BirthDate}</div>
            ) : null}
          </FormGroup>
        </div>
        <div className="idea-box align-items-center mb-4 ">
          <div className="idea-ic">
            <i className="icon-info-circle"></i>
          </div>
          <div className="idea-text">
            <div className="idea-subheading ">
              The lab needs this to process your tests — we respect that your
              identifying gender might be different.
            </div>
          </div>
        </div>
        <div className="step-form pt-3">
          <FormGroup className="form-group ">
            <Select
              classNamePrefix="custom-select"
              placeholder="Choose option"
              type="text"
              id="title"
              name="title"
              onChange={(e) => {
                formik?.setFieldValue("title", e.value);
              }}
              options={titleOptions}
            />
          </FormGroup>
        </div>
      </div>
    </div>
  );
}
export default Step4;
